import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentCanisterService } from 'src/app/services/current-canister.service';
import { CanisterUpsertDto } from 'src/app/shared/generated/model/canister-upsert-dto';
import { CanisterFormComponent } from '../shared/canister-form/canister-form.component';

@Component({
  selector: 'pog-canister-create',
  templateUrl: './canister-create.component.html',
  styleUrls: ['./canister-create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CanisterCreateComponent implements OnInit, OnDestroy {        
  @ViewChild("createNewCanisterForm", { read: CanisterFormComponent }) form: CanisterFormComponent;

  public canister: CanisterUpsertDto = new CanisterUpsertDto();
  public canisterUpsertDto: CanisterUpsertDto;
  private canisterResponse: any;

  editMode = true;

  constructor(
      private currentCanisterService: CurrentCanisterService,
      private router: Router,
      private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
      this.canisterUpsertDto = this.currentCanisterService.createCanisterDto(this.canister);
  }

  ngOnDestroy() {
      this.cdr.detach();
  }

  save(result): void {
    if (!!result) {
        this.canisterResponse = result;
        this.router.navigateByUrl(`/canisters/${result.CanisterID}`);
    } else {
        this.cdr.detectChanges();
    }
  }

  cancelEdit() {
      this.router.navigateByUrl("/canisters");
  }

  canExit() {
      if (this.canisterResponse) {
          return true;
      } else {
          return JSON.stringify(this.canisterUpsertDto) === JSON.stringify(this.form.canisterUpsertDto);
      }
  }
}
