<form class="form grid-12" #reportForm="ngForm">
    <div class="g-col-6">
        <esa-material-ff
            name="ReportingYearID"
            [editMode]="true"
            [required]="true"
            label="Reporting Year"
            type="dropdown"
            [options]="years"
            optionLabel="YearName"
            optionValue="YearID"
            [(ngModel)]="annualReportYear">
        </esa-material-ff>
    </div>

    <div class="g-col-6" style="margin-top: 1rem">
        <esa-material-button
            style="margin-right: 1rem"
            type="primary"
            label="Update"
            [tooltip]="
                !reportForm.form.valid
                    ? 'You are missing required fields.'
                    : ''
            "
            (click)="submitReportYear(form)"
            [disabled]="!reportForm.form.valid"
        >
        </esa-material-button>
    </div>
    
</form>