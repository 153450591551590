import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LinkRendererComponent } from 'src/app/shared/components/ag-grid/link-renderer/link-renderer.component';
import { ProjectService } from 'src/app/shared/generated/api/project.service';
import { PermissionEnum } from 'src/app/shared/generated/enum/permission-enum';
import { ProjectDto } from 'src/app/shared/generated/model/project-dto';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { RightsEnum } from 'src/app/shared/models/enums/rights.enum';
import { DateColumnCreatorService } from 'src/app/shared/services/date-column-creator/date-column-creator.service';

@Component({
  selector: 'pog-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class ProjectListComponent implements OnInit {
  @ViewChild("ProjectsGrid") projectsGrid: AgGridAngular;

    public currentUser: UserDto;
    public projects: ProjectDto[];

    public rowData = [];
    public columnDefs: ColDef[];
    public defaultColDef: any;

    user: Subscription;
    getProjectRequest: Subscription;

    constructor(
        private projectService: ProjectService,
        private authenticationService: AuthenticationService,
        private dateColumnCreator: DateColumnCreatorService,
        private cdr: ChangeDetectorRef,
        private router: Router
    ) {
      this.columnDefs = [
                {
                    headerName: "Name",
                    headerTooltip: "Name",
                    valueGetter: function (params: any) {
                        return {
                            LinkValue: params.data.ProjectID,
                            LinkDisplay: params.data.ProjectName,
                        };
                    },
                    cellRendererFramework: LinkRendererComponent,
                    cellRendererParams: { inRouterLink: "/projects/" },
                    filterValueGetter: function (params: any) {
                        return params.node.rowPinned ? null : params.data.ProjectName;
                    },
                    comparator: function (linkA, linkB, nodeA, nodeB, isDescending) {
                        let valueA = linkA.LinkDisplay.toLowerCase();
                        let valueB = linkB.LinkDisplay.toLowerCase();
      
                        return valueA.localeCompare(valueB, undefined, {
                            numeric: true,
                            sensitivity: "base",
                        });
                    },
                    width: 500,
                    sort: "asc",
                },
                this.dateColumnCreator.createDateColumnDef("Date", "ProjectDate", "M/dd/YYYY"),
            ];

        this.defaultColDef = {
            sortable: true,
            filter: true,
            resizable: true,
            floatingFilter: true,
            suppressMenu: true,
        };
    }

    ngOnInit(): void {
        this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.user.unsubscribe();
        this.getProjectRequest.unsubscribe();
    }

    navigateToProjectCreatePage() {
        this.router.navigate(["/projects/create"]);
    }

    onProjectsGridReady(gridEvent) {
        this.projectsGrid.api.showLoadingOverlay();

        this.getProjectRequest = this.projectService.projectsGet().subscribe((results) => {
            this.rowData = results;
            this.projectsGrid.api.hideOverlay();
            this.projects = results;
            this.cdr.markForCheck();
        });
    }

    get canCreateProject(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.ProjectRights, RightsEnum.Create);
    }
}