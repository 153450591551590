import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ValueGetterParams } from 'ag-grid-community';
import { CustomDropdownFilterComponent } from 'src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component';
import { EquipmentService } from 'src/app/shared/generated/api/equipment.service';
import { YearService } from 'src/app/shared/generated/api/year.service';
import { ReportBaseGenerateComponent } from '../shared/report-base-generate/report-base-generate.component';
import { LinkRendererComponent } from 'src/app/shared/components/ag-grid/link-renderer/link-renderer.component';
import { DateColumnCreatorService } from 'src/app/shared/services/date-column-creator/date-column-creator.service';

@Component({
  selector: 'pog-report-equipment-generate',
  templateUrl: './report-equipment-generate.component.html',
  styleUrls: ['./report-equipment-generate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportEquipmentGenerateComponent extends ReportBaseGenerateComponent implements OnInit {
  @ViewChild("EquipmentGrid") equipmentGrid: AgGridAngular;

  constructor(
    private equipmentService: EquipmentService,
    private dateColumnCreator: DateColumnCreatorService,
    yearService: YearService,
    cdr: ChangeDetectorRef
  ) { 
    super(yearService, cdr);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  setColumnDefinitions() {
    this.columnDefs = [
      {
        
        headerName: "Serial Number",
        headerTooltip: "SerialNumber",
        valueGetter: function (params: any) {
            return {
                LinkValue: params.data.EquipmentID,
                LinkDisplay: params.data.SerialNumber,
            };
        },
        cellRendererFramework: LinkRendererComponent,
        cellRendererParams: { inRouterLink: "/equipment/" },
        filterValueGetter: function (params: any) {
            return params.node.rowPinned
                ? null
                : params.data.SerialNumber;
        },
        comparator: function (
            linkA,
            linkB,
            nodeA,
            nodeB,
            isDescending
        ) {
            let valueA = linkA.LinkDisplay.toLowerCase();
            let valueB = linkB.LinkDisplay.toLowerCase();

            return valueA.localeCompare(valueB, undefined, {
                numeric: true,
                sensitivity: "base",
            });
        },
        minWidth: 400,
        sort: "asc",
      },
      {
          headerName: "Equipment Type",
          field: "EquipmentType.EquipmentTypeDisplayName",
          tooltipField: "EquipmentType.EquipmentTypeDisplayName",
          filterFramework: CustomDropdownFilterComponent,
          filterParams: {
              field: "EquipmentType.EquipmentTypeDisplayName",
          },
          minWidth: 200,
      },
      {
          headerName: "Equipment Type (if Other)",
          field: "EquipmentTypeOther",
          tooltipField: "Equipment Type (Other)",
          minWidth: 225,
      },
      {
          headerName: "Seal Type",
          field: "SealType.SealTypeDisplayName",
          tooltipField: "SealType.SealTypeDisplayName",
          filterFramework: CustomDropdownFilterComponent,
          filterParams: {
              field: "SealType.SealTypeDisplayName",
          },
          minWidth: 200,
      },
      {
        headerName: "Manufacturer",
        field: "Manufacturer.ManufacturerDisplayName",
        tooltipField: "Manufacturer.ManufacturerDisplayName",
        filterFramework: CustomDropdownFilterComponent,
        filterParams: {
            field: "Manufacturer.ManufacturerDisplayName",
        },
        minWidth: 200,
      },
      {
        headerName: "Year Manufactured",
        field: "ManufactureYear",
        tooltipField: "Year Manufactured",
        minWidth: 175,
      },
      {
        headerName: "Voltage Capacity (kilovolts, kV)",
        field: "VoltageCapacity",
        filter: 'agNumberColumnFilter',
        minWidth: 250,
      },
      {
        headerName: "Nameplate Capacity (lb)",
        field: "NameplateCapacity",
        filter: 'agNumberColumnFilter',
        minWidth: 200,
      },
      {
        headerName: "Covered Insulating Gas",
        field: "CoveredInsulatingGas.CoveredInsulatingGasDisplayName",
        tooltipField: "CoveredInsulatingGas.CoveredInsulatingGasDisplayName",
        filterFramework: CustomDropdownFilterComponent,
        filterParams: {
            field: "CoveredInsulatingGas.CoveredInsulatingGasDisplayName",
        },
        minWidth: 200,
      },
      {
        headerName: "Covered Insulating Gas' GWP",
        minWidth: 225,
      },
      {
        headerName: "Jointly Owned?",
        valueGetter: function () {
          return 'no';
        },
        minWidth: 150,
      },
      {
        headerName: "GIE Owner's Equity Share (%)",
        valueGetter: function () {
          return '100%';
        },
        minWidth: 225,
      },
      {
        headerName: "Acquired after 12/31/21?",
        valueGetter: function (params: any) {
          return new Date(params.data.CreateDate) > new Date('12/31/2021') ? 'yes' : 'no';
        },
        minWidth: 200,
      },
      {
        headerName: "Covered Insulating Gas at Activation (lb)",
        minWidth: 300,
      },
      {
        headerName: "Active or Connected to Electrical Power System after 1/1?",
        valueGetter: function (params: any) {
          return 'yes';
        },
        minWidth: 450,
      },
      {
        headerName: "Active or Connected to Electrical Power System after 12/31?",
        valueGetter: this.activeAfterDec31Getter.bind(this),
        minWidth: 450,
      },
      {
        headerName: "Still Owned on 12/31?",
        valueGetter: function (params: any) {
          return 'yes';
        },
        minWidth: 175,
      },
      this.dateColumnCreator.createDateColumnDef("If not active on 12/31, Last Date Active (mm/dd/yyyy)", "RetirementDate", "MM/dd/YYYY", 400),
      {
        headerName: "Amount of Gas Transferred Out of Device Removed from Regular Use (lb)",
        field: "GasTransferredOutAmount",
        filter: 'agNumberColumnFilter',
        minWidth: 525,
      },
    ];
  }

  activeAfterDec31Getter = function(params: ValueGetterParams) {
    return !params.data.RetirementDate || new Date(params.data.RetirementDate) > new Date(`12/31/${this.annualReportYear}`) ? 'yes' : 'no';
  }

  refreshGrid() {
    this.equipmentGrid.api.showLoadingOverlay();
    this.getReportDataRequest = this.equipmentService
        .equipmentGet()
        .subscribe((results) => {
            this.rowData = results.filter(r => !r.RetirementDate || new Date(r.RetirementDate) > new Date(`01/01/${this.annualReportYear}`));
            this.rowData.forEach(r => {
              if (!!r.RetirementDate) {
                r.RetirementDate = new Date(r.RetirementDate).toLocaleDateString();
              }
            });
            this.equipmentGrid.api.hideOverlay();
            this.cdr.markForCheck();
        });
  }
}