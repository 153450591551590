import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LinkRendererComponent } from 'src/app/shared/components/ag-grid/link-renderer/link-renderer.component';
import { CustomDropdownFilterComponent } from 'src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component';
import { CanisterService } from 'src/app/shared/generated/api/canister.service';
import { PermissionEnum } from 'src/app/shared/generated/enum/permission-enum';
import { CanisterDto } from 'src/app/shared/generated/model/canister-dto';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { RightsEnum } from 'src/app/shared/models/enums/rights.enum';
import { DateColumnCreatorService } from 'src/app/shared/services/date-column-creator/date-column-creator.service';

@Component({
    selector: "pog-canister-list",
    templateUrl: "./canister-list.component.html",
    styleUrls: ["./canister-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CanisterListComponent implements OnInit {
    @ViewChild("CanistersGrid") canistersGrid: AgGridAngular;

    public currentUser: UserDto;
    public canisters: CanisterDto[];

    public rowData = [];
    public columnDefs: ColDef[];
    public defaultColDef: any;

    user: Subscription;
    getCanisterRequest: Subscription;

    constructor(
        private canisterService: CanisterService,
        private authenticationService: AuthenticationService,
        private dateColumnCreator: DateColumnCreatorService,
        private cdr: ChangeDetectorRef,
        private router: Router
    ) {
        this.columnDefs = [
            {
                headerName: "Gas Container or Gas Cart ID",
                headerTooltip: "GasContainerOrGasCartID",
                valueGetter: function (params: any) {
                    return {
                        LinkValue: params.data.CanisterID,
                        LinkDisplay: params.data.GasContainerOrGasCartID,
                    };
                },
                cellRendererFramework: LinkRendererComponent,
                cellRendererParams: { inRouterLink: "/canisters/" },
                filterValueGetter: function (params: any) {
                    return params.node.rowPinned
                        ? null
                        : params.data.GasContainerOrGasCartID;
                },
                comparator: function (
                    linkA,
                    linkB,
                    nodeA,
                    nodeB,
                    isDescending
                ) {
                    let valueA = linkA.LinkDisplay.toLowerCase();
                    let valueB = linkB.LinkDisplay.toLowerCase();

                    return valueA.localeCompare(valueB, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    });
                },
                minWidth: 300,
                sort: "asc",
            },
            {
                headerName: "Vessel Type",
                field: "VesselType.VesselTypeDisplayName",
                tooltipField: "VesselType.VesselTypeDisplayName",
                filterFramework: CustomDropdownFilterComponent,
                filterParams: {
                    field: "VesselType.VesselTypeDisplayName",
                },
                minWidth: 100,
            },
            {
                headerName: "Property of GIE Owner",
                field: "PropertyOfGieStatus.PropertyOfGieStatusDisplayName",
                tooltipField:
                    "PropertyOfGieStatus.PropertyOfGieStatusDisplayName",
                filterFramework: CustomDropdownFilterComponent,
                filterParams: {
                    field: "PropertyOfGieStatus.PropertyOfGieStatusDisplayName",
                },
                minWidth: 100,
            },
            {
                headerName: "Status",
                field: "CanisterStatus.CanisterStatusDisplayName",
                tooltipField: "CanisterStatus.CanisterStatusDisplayName",
                filterFramework: CustomDropdownFilterComponent,
                filterParams: {
                    field: "CanisterStatus.CanisterStatusDisplayName",
                },
                minWidth: 200,
            },
            {
                headerName: "Gas Container Nickname",
                field: "GasContainerNickname",
                tooltipField: "GasContainerNickname",
                minWidth: 300,
            },
        ];

        this.defaultColDef = {
            sortable: true,
            filter: true,
            resizable: true,
            floatingFilter: true,
            suppressMenu: true,
        };
    }

    ngOnInit(): void {
        this.user = this.authenticationService
            .getCurrentUser()
            .subscribe((result) => {
                this.currentUser = result;
                this.cdr.markForCheck();
            });
    }

    ngOnDestroy(): void {
        this.user.unsubscribe();
        this.getCanisterRequest.unsubscribe();
    }

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    navigateToCanisterCreatePage() {
        this.router.navigate(["/canisters/create"]);
    }

    onCanistersGridReady(gridEvent) {
        this.canistersGrid.api.showLoadingOverlay();

        this.getCanisterRequest = this.canisterService
            .canistersGet()
            .subscribe((results) => {
                this.rowData = results;
                this.canistersGrid.api.hideOverlay();
                this.canisters = results;
                this.cdr.markForCheck();
            });
    }

    get canCreateCanister(): boolean {
        return this.authenticationService.hasPermission(
            this.currentUser,
            PermissionEnum.CanisterRights,
            RightsEnum.Create
        );
    }
}