import * as L from 'leaflet';
import 'leaflet.markercluster';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { LocationFormComponent } from 'src/app/pages/shared/location-form/location-form.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { EquipmentLocationDto } from 'src/app/shared/generated/model/equipment-location-dto';
import { CanisterLocationDto } from 'src/app/shared/generated/model/canister-location-dto';

@Component({
    selector: 'pog-location-map-explorer',
    templateUrl: './../../../shared/location-form/location-form.component.html',
    styleUrls: ['./location-map-explorer.component.scss']
})
export class LocationMapExplorerComponent extends LocationFormComponent implements OnInit {
    @Input() locations: { equipment: EquipmentLocationDto[], canisters: CanisterLocationDto[] };

    equipmentLayer: L.markerClusterGroup = L.markerClusterGroup({
        showCoverageOnHover: false,
        iconCreateFunction: this.equipmentClusterIconCreateFunction.bind(this)
        });
    canisterLayer: L.markerClusterGroup = L.markerClusterGroup({
        showCoverageOnHover: false,
        iconCreateFunction: this.canisterClusterIconCreateFunction.bind(this)
        });

    constructor(
        authenticationService: AuthenticationService,
        cdr: ChangeDetectorRef
    ) {
        super(authenticationService, cdr);
    }

    ngOnInit(): void { }

    saveForm() {
        throw new Error('Method not implemented.');
    }

    refreshLocation() {
        throw new Error('Method not implemented.');
    }

    displayExistingMapFeatures() {
        this.buildMarkersForFeatureGroup(this.equipmentLayer, this.locations?.equipment);
        this.buildMarkersForFeatureGroup(this.canisterLayer, this.locations?.canisters, this.orangeIcon);

        this.equipmentLayer.addTo(this.map);
        this.canisterLayer.addTo(this.map);

        this.modifyEquipmentFeatureGroup();
        this.modifyCanisterFeatureGroup();

        this.layerControl.addOverlay(this.equipmentLayer, '<img src="./../../../../../assets/main/map-icons/marker-icon-blue.png" height="16" style="display: inline"> Equipment');
        this.layerControl.addOverlay(this.canisterLayer, '<img src="./../../../../../assets/main/map-icons/marker-icon-orange.png" height="16" style="display: inline"> Canisters');

        this.fitExistingMarkerBounds();
    }

    buildMarkersForFeatureGroup(featureGroup, items, icon = this.blueIcon) {
        if (!!items) {
            items.forEach(x => {
                let marker = new L.marker([x.Latitude, x.Longitude], { icon: icon, riseOnHover: true, otherData: x });
                featureGroup.addLayer(marker);
            });
        }
    }

    modifyEquipmentFeatureGroup() {
        this.equipmentLayer.eachLayer((innerLayer) => {
            const data = innerLayer?.options?.otherData;
            innerLayer.bindPopup(`<a href="/equipment/${data?.EquipmentID}" target="_blank">${data?.SerialNumber}</a> (${data?.EquipmentTypeDisplayName})`)
        });
    }

    modifyCanisterFeatureGroup() {
        this.canisterLayer.eachLayer((innerLayer) => {
            const data = innerLayer?.options?.otherData;
            innerLayer.bindPopup(`<a href="/canisters/${data?.CanisterID}" target="_blank">${data?.GasContainerOrGasCartID}</a>`)
        });
    }

    fitExistingMarkerBounds() {
        let bounds = L.latLngBounds([]);
        if (this.equipmentLayer.getLayers()?.length) {
            bounds.extend(this.equipmentLayer.getBounds());
        }
        if (this.canisterLayer.getLayers()?.length) {
            bounds.extend(this.canisterLayer.getBounds());
        }
        if (!bounds.isValid()) {
            return;
        }
        this.map.fitBounds(bounds);
    }

    public equipmentClusterIconCreateFunction(cluster) {
        return this.clusterIconCreateFunction(cluster, 'equipment');
    }

    public canisterClusterIconCreateFunction(cluster) {
        return this.clusterIconCreateFunction(cluster, 'canister');
    }

    private clusterIconCreateFunction(cluster, entityName) {
        const childCount = cluster.getChildCount();
        const customClassName = `pog-${entityName}-cluster`;

        return new L.DivIcon({
            html: '<div><span>' + childCount + '</span></div>',
            className: `marker-cluster ${customClassName} marker-cluster-small`,
            iconSize: new L.Point(40, 40) });
    }
}
