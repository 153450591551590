import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LinkRendererComponent } from 'src/app/shared/components/ag-grid/link-renderer/link-renderer.component';
import { CustomDropdownFilterComponent } from 'src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component';
import { EquipmentService } from 'src/app/shared/generated/api/equipment.service';
import { PermissionEnum } from 'src/app/shared/generated/enum/permission-enum';
import { EquipmentDto } from 'src/app/shared/generated/model/equipment-dto';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { RightsEnum } from 'src/app/shared/models/enums/rights.enum';
import { FileService } from 'src/app/shared/services/file/file.service';

@Component({
    selector: "pog-equipment-list",
    templateUrl: "./equipment-list.component.html",
    styleUrls: ["./equipment-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentListComponent implements OnInit {
    @ViewChild("EquipmentGrid") equipmentGrid: AgGridAngular;

    public currentUser: UserDto;
    public equipment: EquipmentDto[];

    public rowData = [];
    public columnDefs: ColDef[];
    public defaultColDef: any;

    user: Subscription;
    getEquipmentRequest: Subscription;

    constructor(
        private equipmentService: EquipmentService,
        private authenticationService: AuthenticationService,
        private cdr: ChangeDetectorRef,
        private router: Router,
        private fileService: FileService,
    ) {
        this.columnDefs = [
            {
                headerName: "Serial Number",
                headerTooltip: "SerialNumber",
                valueGetter: function (params: any) {
                    return {
                        LinkValue: params.data.EquipmentID,
                        LinkDisplay: params.data.SerialNumber,
                    };
                },
                cellRendererFramework: LinkRendererComponent,
                cellRendererParams: { inRouterLink: "/equipment/" },
                filterValueGetter: function (params: any) {
                    return params.node.rowPinned
                        ? null
                        : params.data.SerialNumber;
                },
                comparator: function (
                    linkA,
                    linkB,
                    nodeA,
                    nodeB,
                    isDescending
                ) {
                    let valueA = linkA.LinkDisplay.toLowerCase();
                    let valueB = linkB.LinkDisplay.toLowerCase();

                    return valueA.localeCompare(valueB, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    });
                },
                minWidth: 400,
                sort: "asc",
            },
            {
                headerName: "Equipment Type",
                field: "EquipmentType.EquipmentTypeDisplayName",
                tooltipField: "EquipmentType.EquipmentTypeDisplayName",
                filterFramework: CustomDropdownFilterComponent,
                filterParams: {
                    field: "EquipmentType.EquipmentTypeDisplayName",
                },
                minWidth: 200,
            },
            {
                headerName: "Seal Type",
                field: "SealType.SealTypeDisplayName",
                tooltipField: "SealType.SealTypeDisplayName",
                filterFramework: CustomDropdownFilterComponent,
                filterParams: {
                    field: "SealType.SealTypeDisplayName",
                },
                minWidth: 200,
            },
            {
                headerName: "Status",
                field: "EquipmentStatus.EquipmentStatusDisplayName",
                tooltipField: "EquipmentStatus.EquipmentStatusDisplayName",
                filterFramework: CustomDropdownFilterComponent,
                filterParams: {
                    field: "EquipmentStatus.EquipmentStatusDisplayName",
                },
                minWidth: 200,
            },
            {
                headerName: "Department",
                field: "Department.DepartmentDisplayName",
                tooltipField: "Department.DepartmentDisplayName",
                filterFramework: CustomDropdownFilterComponent,
                filterParams: {
                    field: "Department.DepartmentDisplayName",
                },
                minWidth: 200,
            },
            {
                headerName: "Contact Name",
                field: "ContactName",
                tooltipField: "ContactName",
                minWidth: 200,
            },
            {
                headerName: "Latitude",
                field: "Latitude",
                tooltipField: "Latitude",
                minWidth: 200,
                filter: "agNumberColumnFilter"
            },
            {
                headerName: "Longitude",
                field: "Longitude",
                tooltipField: "Longitude",
                minWidth: 200,
                filter: "agNumberColumnFilter"
            },
        ];

        this.defaultColDef = {
            sortable: true,
            filter: true,
            resizable: true,
            floatingFilter: true,
            suppressMenu: true,
        };
    }

    ngOnInit(): void {
        this.user = this.authenticationService
            .getCurrentUser()
            .subscribe((result) => {
                this.currentUser = result;
                this.cdr.markForCheck();
            });
    }

    ngOnDestroy(): void {
        this.user.unsubscribe();
        this.getEquipmentRequest.unsubscribe();
    }

    onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    navigateToEquipmentCreatePage() {
        this.router.navigate(["/equipment/create"]);
    }

    onEquipmentGridReady(gridEvent) {
        this.equipmentGrid.api.showLoadingOverlay();

        this.getEquipmentRequest = this.equipmentService
            .equipmentGet()
            .subscribe((results) => {
                this.rowData = results;
                this.equipmentGrid.api.hideOverlay();
                this.equipment = results;
                this.cdr.markForCheck();
            });
    }

    get canCreateEquipment(): boolean {
        return this.authenticationService.hasPermission(
            this.currentUser,
            PermissionEnum.EquipmentRights,
            RightsEnum.Create
        );
    }

    downloadFile(event, fileCanonicalName) {
        event.preventDefault();
        event.stopPropagation();
        this.fileService.downloadEquipmentStaticFile(fileCanonicalName).subscribe((result) => {
            if (!result) {
                return;
            }
            
            const file = new File([result], `${fileCanonicalName}.pdf`);
            if (navigator.msSaveBlob) {
                return navigator.msSaveBlob(file, file.name);
            }
  
            this.downloadAsHref(URL.createObjectURL(file), file.name);
        });
    }
  
    downloadAsHref(href: string, filename: string) {
        const tempDownloadLink = document.createElement("a");
        tempDownloadLink.href = href;
        tempDownloadLink.download = filename;
  
        document.body.appendChild(tempDownloadLink);
        tempDownloadLink.click();
        document.body.removeChild(tempDownloadLink);
    }
}