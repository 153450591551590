<div class="list-view">
    <div class="page-header">
        <div class="actions-bar flex-between">
            <h1 class="page-title">Map Explorer</h1>
        </div>
    </div>

    <div>
        <ng-container *ngIf="locations$ | async as l">
            <pog-location-map-explorer
                [locations]="l"
                [showLatLngDetails]="false">
            </pog-location-map-explorer>
        </ng-container>
    </div>
</div>
