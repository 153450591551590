import { Injectable } from '@angular/core';
import { Alert } from '../models/alert';
import { AlertContext } from '../models/enums/alert-context.enum';
import { BehaviorSubject } from 'rxjs';
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
    providedIn: 'root'
})
export class AlertService {
    constructor(private _snackBar: MatSnackBar) { }

    public alerts: Alert[] = [];
    public alertSubject: BehaviorSubject<Alert[]> = new BehaviorSubject([]);

    // Pass in a removeAlertTime of 0 if you don't want it removed
    pushAlert(alert: Alert, removeAlertTime: number = 5000): void {
        if (alert.uniqueCode ){
            if (this.alerts.some((x) => x.uniqueCode === alert.uniqueCode)){
                return; // don't push a duplicate alert if it has a unique token.
            }
        }

        this._snackBar.open(alert.message, alert.dismissable ? "Dismiss" : null, {
            duration: removeAlertTime,
            verticalPosition: "top",
            horizontalPosition: "center",
            panelClass: "notif-success",
        });

        this.alerts.push(alert);
        this.alertSubject.next(this.alerts);

        if (removeAlertTime) {
            setTimeout(() => {
                this.removeAlert(alert);
            }, removeAlertTime);
        }
    }

    removeAlert(alert: Alert): void {
        const index: number = this.alerts.indexOf(alert);
        this.alerts.splice(index, 1);
        this.alertSubject.next(this.alerts);
    }

    getAlerts(): Alert[] {
        return this.alerts;
    }

    clearAlerts(): void {
        this.alerts = [];
        this.alertSubject.next(this.alerts);
    }

    pushNotFoundUnauthorizedAlert(){
        this.pushAlert(new Alert("The page you are trying to access was not found, or you do not have permission to view it.", AlertContext.Info, true, AlertService.NOT_FOUND_UNAUTHORIZED));
        this.alertSubject.next(this.alerts);
    }


    public static NOT_FOUND_UNAUTHORIZED = "NotFoundUnauthorized";
    public static USERS_AWAITING_CONFIGURATION = "UsersAwaitingConfiguration";
}
