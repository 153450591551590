<div *ngIf="isLoading" class="text-center">
    <span class="fa fa-spinner loading-spinner"></span>
</div>

<div class="d-inline" *ngIf="fieldDefinition$ | async as fieldDefinition">
    <ng-template #popTitle>
        <div class="field-definition-title flex-between">
            <div class="mt-1">{{ fieldDefinition.FieldDefinitionType.FieldDefinitionTypeDisplayName }}</div>
            <esa-material-button type="clear" icon="edit" [hidden]="isEditing" *ngIf="showEditButton()" (click)="enterEdit()"> </esa-material-button>
        </div>
    </ng-template>
    <ng-template #popContent>
        <ng-container *ngIf="!isEditing">
            <div *ngIf="showEditButton()" style="position: relative; min-height: 30px;">
                <div *ngIf="!emptyContent" [innerHtml]="fieldDefinition.FieldDefinitionValue"></div>
                <div *ngIf="emptyContent" class="hoverEmptySpace"></div>
            </div>
            <div *ngIf="!showEditButton()" [innerHtml]="fieldDefinition.FieldDefinitionValue"></div>
        </ng-container>
        <div *ngIf="isEditing">
            <editor #tinyMceEditor
                [init]="{toolbar: false, menubar: false, height:200,width:250, plugins: 'quickbars'}"
                [(ngModel)]="editedContent"
                ></editor>
            <div class="actions-bar" style="margin-top: 1rem;">
                <esa-material-button type="primary" icon="save" label="Save" (click)="saveEdit()" style="margin-right: 1rem"></esa-material-button>
                <esa-material-button type="clear" icon="cancel" label="Cancel" (click)="cancelEdit(p)"></esa-material-button>
            </div>
        </div>
    </ng-template>

    <ng-container *ngIf="showEditButton()">
        <div class="d-inline-block">
            <span style="overflow:hidden; text-overflow:ellipsis">
                <span *ngIf="!iconOnly">{{ getLabelText() }}</span>
                <mat-icon class="help-icon" [popper]="popContent" [popperTitle]="popTitle" [popperOptions]="popperOptions">help_outline</mat-icon>
            </span>
        </div>
    </ng-container>
    <ng-container *ngIf="!showEditButton()">
        <span>
            <span *ngIf="!iconOnly">{{ getLabelText() }}</span>
            <mat-icon class="help-icon" [popper]="popContent" [popperTitle]="popTitle" [popperOptions]="popperOptions">help_outline</mat-icon>
        </span>
    </ng-container>
</div>