export class ArrayHelpers {
    static sortByProperty(array: Array<any>, property: string, asc: boolean = true): Array<any> {
        return array.sort((a, b) => {
            if (asc) {
                return (a[property] > b[property]) ? 1 : (a[property] < b[property]) ? - 1 : 0;
            } else {
                return (a[property] < b[property]) ? 1 : (a[property] > b[property]) ? - 1 : 0;
            }
        })
    }
}