<div class="detail-view">
    <div class="page-header pb-3">
        <h1 class="page-title">Canisters Annual Reporting</h1>
        <div class="g-col-6" style="display: flex; flex-direction: column; justify-content: center">
            <p>
                This page allows you to download the data necessary to complete the CARB report tab for Canisters.
            </p>
        </div>
    </div>

    <pog-report-form 
        #generateReportForm
        [annualReportYear]="annualReportYearID"
        [years]="years"
        (formSubmitted)="generate($event)"></pog-report-form>

    <div [hidden]="!columnDefs.length">
        <hr/>
        <div style="width:100%; display: flex">
            <h3 style="display: inline-block">Annual Report for {{annualReportYear}}</h3>
            <div style="flex:auto"></div>
            <div class="filter-btn-group align-right mb-3">
                <pog-clear-grid-filters-button class="mr-2" [grid]="canisterGrid"></pog-clear-grid-filters-button>
                <pog-csv-download-button [grid]="canisterGrid" fileName="canisterReport" colIDsToExclude=""></pog-csv-download-button>
            </div>
        </div>

        <div>
            <div class="table-responsive">
                <ag-grid-angular
                    #CanisterGrid
                    style="width: 100%; height: 100%"
                    class="ag-theme-balham"
                    [rowData]="rowData"
                    [columnDefs]="columnDefs"
                    [defaultColDef]="defaultColDef"
                    [suppressMenuHide]="true"
                    (firstDataRendered)="onFirstDataRendered($event)">
                </ag-grid-angular>
            </div>
        </div>
    </div>
    <div class="form grid-12">
        <div class="required-note g-col-12">
            <i class="fas fa-bolt"></i> Required field
        </div>
    </div>
</div>