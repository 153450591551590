import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, APP_INITIALIZER, ErrorHandler } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { HomeIndexComponent } from "./pages/home/home-index/home-index.component";
import { UserListComponent } from "./pages/user-list/user-list.component";
import { RouterModule } from "@angular/router";
import { UserDetailComponent } from "./pages/user-detail/user-detail.component";
import { UserEditComponent } from "./pages/user-edit/user-edit.component";
import { DecimalPipe, CurrencyPipe, DatePipe } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { LoginCallbackComponent } from "./pages/login-callback/login-callback.component";
import { HelpComponent } from "./pages/help/help.component";
import { CreateUserCallbackComponent } from "./pages/create-user-callback/create-user-callback.component";
import { FieldDefinitionListComponent } from "./pages/field-definition-list/field-definition-list.component";
import { FieldDefinitionEditComponent } from "./pages/field-definition-edit/field-definition-edit.component";
import { HttpErrorInterceptor } from "./shared/interceptors/httpErrorInterceptor";
import { environment } from "src/environments/environment";
import { AppInsightsService } from "./shared/services/app-insights.service";
import { GlobalErrorHandlerService } from "./shared/services/global-error-handler.service";
import { CustomPageListComponent } from "./pages/custom-page-list/custom-page-list.component";
import { CustomPageDetailComponent } from "./pages/custom-page-detail/custom-page-detail.component";
import { CustomPageEditPropertiesComponent } from "./pages/custom-page-edit-properties/custom-page-edit-properties.component";
import { SelectDropDownModule } from "ngx-select-dropdown";
import { SharedModule } from "./shared/shared.module";
import { ApiModule } from "./shared/generated/api.module";
import { Configuration } from "./shared/generated/configuration";
import {
    IPublicClientApplication,
    PublicClientApplication,
    InteractionType,
} from "@azure/msal-browser";
import {
    MsalGuard,
    MsalBroadcastService,
    MsalModule,
    MsalService,
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MsalGuardConfiguration,
    MsalRedirectComponent,
    MsalInterceptorConfiguration,
    MSAL_INTERCEPTOR_CONFIG,
} from "@azure/msal-angular";
import { AuthInterceptor } from "./shared/interceptors/auth-interceptor";
import { msalConfig } from "./auth.config";
import { DashboardManageComponent } from "./pages/dashboards/dashboard-manage/dashboard-manage.component";
import { AgGridModule } from "ag-grid-angular";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from "@angular/material/form-field";
import { MatChipsModule } from "@angular/material/chips";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";
import { MatRadioModule } from "@angular/material/radio";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatCardModule } from "@angular/material/card";
import { EsaMaterialButtonComponent, EsaMaterialFormFieldComponent } from "esa-material-form-field";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSelectModule } from "@angular/material/select";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { ButtonRendererComponent } from "./shared/components/ag-grid/button-renderer/button-renderer.component";
import { ProjectListComponent } from './pages/project/project-list/project-list.component';
import { ProjectCreateComponent } from './pages/project/project-create/project-create.component';
import { BypassHtmlPipe } from './pipes/bypass-html.pipe';
import { ProjectDetailComponent } from "./pages/project/project-detail/project-detail.component";
import { ProjectFormComponent } from "./pages/project/shared/project-form/project-form.component";
import { ProjectDetailSummaryComponent } from "./pages/project/project-detail/project-detail-summary/project-detail-summary.component";
import { UserInviteComponent } from "./pages/user-invite/user-invite.component";
import { ProjectFileListComponent } from "./pages/project/project-detail/project-file-list/project-file-list.component";
import { EquipmentListComponent } from './pages/equipment/equipment-list/equipment-list.component';
import { EquipmentCreateComponent } from './pages/equipment/equipment-create/equipment-create.component';
import { EquipmentFormComponent } from './pages/equipment/shared/equipment-form/equipment-form.component';
import { EquipmentDetailComponent } from './pages/equipment/equipment-detail/equipment-detail.component';
import { EquipmentDetailSummaryComponent } from './pages/equipment/equipment-detail/equipment-detail-summary/equipment-detail-summary.component';
import { CanisterListComponent } from './pages/canister/canister-list/canister-list.component';
import { CanisterCreateComponent } from './pages/canister/canister-create/canister-create.component';
import { CanisterFormComponent } from './pages/canister/shared/canister-form/canister-form.component';
import { CanisterDetailComponent } from './pages/canister/canister-detail/canister-detail.component';
import { CanisterDetailSummaryComponent } from './pages/canister/canister-detail/canister-detail-summary/canister-detail-summary.component';
import { CanisterYearEndWeightListComponent } from './pages/canister/canister-detail/canister-year-end-weight-list/canister-year-end-weight-list.component'
import { YearEndWeightFormComponent } from "./pages/canister/shared/year-end-weight-form/year-end-weight-form.component";
import { ReportFormComponent } from './pages/report/shared/report-form/report-form.component';
import { ReportEquipmentGenerateComponent } from './pages/report/report-equipment-generate/report-equipment-generate.component';
import { ReportCanisterGenerateComponent } from './pages/report/report-canister-generate/report-canister-generate.component';
import { CanisterFileListComponent } from './pages/canister/canister-detail/canister-file-list/canister-file-list.component';
import { EquipmentFileListComponent } from './pages/equipment/equipment-detail/equipment-file-list/equipment-file-list.component';
import { EquipmentLocationComponent } from './pages/equipment/equipment-detail/equipment-location/equipment-location.component';
import { EquipmentLocationFormComponent } from './pages/equipment/equipment-detail/equipment-location/equipment-location-form/equipment-location-form.component';
import { CanisterLocationComponent } from "./pages/canister/canister-detail/canister-location/canister-location.component";
import { CanisterLocationFormComponent } from "./pages/canister/canister-detail/canister-location/canister-location-form/canister-location-form.component";
import { LocationListComponent } from './pages/location/location-list/location-list.component';
import { LocationMapExplorerComponent } from './pages/location/location-list/location-map-explorer/location-map-explorer.component';
import { DatadogService } from "./shared/services/datadog.service";

declare global {
    interface Navigator {
        msSaveBlob?: (blob: any, defaultName?: string) => boolean;
    }
}

const isIE =
    window.navigator.userAgent.indexOf("MSIE ") > -1 ||
    window.navigator.userAgent.indexOf("Trident/") > -1;

export function init_app(appInsightsService: AppInsightsService, datadogService: DatadogService) {
    return () => {
        if (environment.appInsightsInstrumentationKey) {
            appInsightsService.initAppInsights();
        }

        if (environment.datadogClientToken) {
          datadogService.init();
        }
    };
}

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(msalConfig);
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
    };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: null,
    };
}

@NgModule({
    declarations: [
        AppComponent,
        HomeIndexComponent,
        UserListComponent,
        UserDetailComponent,
        UserEditComponent,
        LoginCallbackComponent,
        HelpComponent,
        CreateUserCallbackComponent,
        FieldDefinitionListComponent,
        FieldDefinitionEditComponent,
        CustomPageListComponent,
        CustomPageDetailComponent,
        CustomPageEditPropertiesComponent,
        DashboardManageComponent,
        ButtonRendererComponent,
        ProjectListComponent,
        ProjectDetailComponent,
        ProjectCreateComponent,
        ProjectFormComponent,
        ProjectDetailSummaryComponent,
        BypassHtmlPipe,
        UserInviteComponent,
        ProjectFileListComponent,
        EquipmentListComponent,
        EquipmentCreateComponent,
        EquipmentFormComponent,
        EquipmentDetailComponent,
        EquipmentDetailSummaryComponent,
        CanisterListComponent,
        CanisterCreateComponent,
        CanisterFormComponent,
        CanisterDetailComponent,
        CanisterDetailSummaryComponent,
        CanisterYearEndWeightListComponent,
        YearEndWeightFormComponent,
        ReportFormComponent,
        ReportEquipmentGenerateComponent,
        ReportCanisterGenerateComponent,
        CanisterFileListComponent,
        EquipmentFileListComponent,
        EquipmentLocationComponent,
        EquipmentLocationFormComponent,
        CanisterLocationComponent,
        CanisterLocationFormComponent,
        LocationListComponent,
        LocationMapExplorerComponent,
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule,
        SharedModule.forRoot(),
        FormsModule,
        BrowserAnimationsModule,
        AgGridModule.withComponents([ButtonRendererComponent]),
        SelectDropDownModule,
        ApiModule.forRoot(() => {
            return new Configuration({
                basePath: `${environment.mainAppApiUrl}`,
            });
        }),
        MsalModule,
        MatExpansionModule,
        MatExpansionModule,
        MatSnackBarModule,
        MatChipsModule,
        MatInputModule,
        MatFormFieldModule,
        MatIconModule,
        MatMenuModule,
        MatCardModule,
        MatAutocompleteModule,
        MatDialogModule,
        MsalModule,
        MatRadioModule,
        MatTooltipModule,
        MatButtonModule,
        MsalModule,
        MatMomentDateModule,
        EsaMaterialFormFieldComponent,
        EsaMaterialButtonComponent,
        MatSelectModule,
        MatDatepickerModule,
        MatCheckboxModule,
        NgxMaterialTimepickerModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: init_app,
            deps: [AppInsightsService, DatadogService],
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandlerService,
        },
        DecimalPipe,
        CurrencyPipe,
        DatePipe,
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: "outline", hideRequiredMarker: true } },
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {}
