<div *ngIf="!isLoading" class="detail-view">
    <div class="page-header pb-3">
        <h1 class="page-title">{{ isCreating ? "New" : "Edit" }} Custom Page Properties</h1>
    
        <div class="g-col-6" style="display: flex; flex-direction: column; justify-content: center">
            <p>
                This page allows you to {{ isCreating ? "create" : "edit" }} custom page properties. Select a menu category for the custom page to reside in and
                which roles will be able to view the page. Administrators have viewable rights on all custom pages.
            </p>
        </div>
    </div>

    <div>
        <form class="form grid-12" #updateCustomPagePropertiesForm="ngForm">
            <div class="g-col-12">
                <esa-material-ff
                    name="MenuItem"
                    [editMode]="true"
                    [required]="true"
                    label="Menu"
                    type="dropdown"
                    [options]="menuItems"
                    optionLabel="MenuItemDisplayName"
                    optionValue="MenuItemID"
                    [(ngModel)]="model.MenuItemID">
                </esa-material-ff>

                <esa-material-ff
                    name="CustomPageDisplayName"
                    placeholder="Page Name"
                    [editMode]="true"
                    [required]="true"
                    label="Page Name"
                    type="text"
                    [(ngModel)]="model.CustomPageDisplayName"
                    (ngModelChange)="slugifyPageName($event)">
                </esa-material-ff>
                <div class="error" *ngIf="!validPageName(model.CustomPageDisplayName)">
                    <mat-error>Page name must consist of letters, numbers, spaces, underscores(_), and hyphens(-) only</mat-error>
                </div>

                <esa-material-ff
                    name="CustomPageVanityUrl"
                    placeholder="Vanity URL"
                    [editMode]="true"
                    [required]="true"
                    label="Vanity URL"
                    type="text"
                    [(ngModel)]="model.CustomPageVanityUrl">
                </esa-material-ff>
                <div class="error" *ngIf="!validVanityUrl(model.CustomPageVanityUrl)">
                    <mat-error>Vanity URL must consist of letters, numbers, underscores(_), and hyphens(-) only</mat-error>
                </div>

                <esa-material-ff
                    name="Viewable by Roles"
                    [editMode]="true"
                    label="Viewable by Roles"
                    placeholder="Select Item(s)"
                    [options]="roles"
                    optionLabel="RoleDisplayName"
                    optionValue="RoleID"
                    type="dropdown"
                    [(ngModel)]="model.ViewableRoleIDs"
                    [dropdownMultiple]="true">
                </esa-material-ff>
            </div>
    
            <div class="required-note g-col-12">
                <i class="fas fa-bolt"></i> Required field
            </div>

            <div class="g-col-12 actions-bar" style="margin-top: 1rem">
                <esa-material-button
                    type="primary"
                    label="Save"
                    icon="save"
                    [disabled]="isLoadingSubmit || !updateCustomPagePropertiesForm.form.valid || !validVanityUrl(model.CustomPageVanityUrl) || !validPageName(model.CustomPageDisplayName)"
                    (click)="saveForm(form)"
                    style="margin-right: 1rem">
                </esa-material-button>
                <esa-material-button type="clear" label="Cancel" icon="cancel" (click)="cancelEdit()"> </esa-material-button>
            </div>    
        </form>
    </div>
</div>