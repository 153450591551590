import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CurrentEquipmentService } from 'src/app/services/current-equipment.service';
import { EditViewEventService } from 'src/app/services/edit-view-event.service';
import { EquipmentDto, EquipmentLocationDto, UserDto } from 'src/app/shared/generated/model/models';
import { EquipmentLocationFormComponent } from './equipment-location-form/equipment-location-form.component';

@Component({
  selector: 'pog-equipment-location',
  templateUrl: './equipment-location.component.html',
  styleUrls: ['./equipment-location.component.scss']
})
export class EquipmentLocationComponent implements OnInit, OnDestroy {
  @ViewChild("updateLocationForm", { read: EquipmentLocationFormComponent }) form: EquipmentLocationFormComponent;

  public equipment$: Observable<any>;
  public equipment: EquipmentDto;
  public location: EquipmentLocationDto;
  public editMode = false;
  user: Subscription;
  public currentUser: UserDto;

  constructor(
      private currentEquipmentService: CurrentEquipmentService,
      private authenticationService: AuthenticationService,
      private editViewEventService: EditViewEventService,
      private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
      this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
          this.currentUser = result;
          this.cdr.markForCheck();
      });

      this.equipment$ = this.currentEquipmentService.getCurrentEquipment().pipe(
          map((data) => {
              this.equipment = data;
              this.location = this.currentEquipmentService.createEquipmentLocationDto(this.equipment);
              this.cdr.markForCheck();
              return data;
          })
      );
  }

  ngOnDestroy(): void {
      this.user.unsubscribe();
  }

  refreshEquipment(result: any) {
      this.editMode = false;
      this.currentEquipmentService.setCurrentEquipment(result);
      this.editViewEventService.editButtonClicked.next(this.editMode);
      this.cdr.detectChanges();
  }

  save(result) {
      this.refreshEquipment(result);
  }

  canEdit(): boolean {
      return this.currentEquipmentService.canEditCurrentEquipment(this?.currentUser, this.equipment) && !this.editMode;
  }

  enableEditMode() {
      this.editMode = true;
      this.editViewEventService.editButtonClicked.next(this.editMode);
      this.cdr.markForCheck();
  }

  cancelEdit() {
      this.editMode = false;
      this.editViewEventService.editButtonClicked.next(this.editMode);
      this.cdr.markForCheck();
  }

  canExit() {
      if (this.editMode) {
          return JSON.stringify(this.location) === JSON.stringify(this.form.location);
      } else {
          return true;
      }
  }
}
