import { Injectable } from "@angular/core";
import { CanDeactivate } from "@angular/router";
import { Observable, of } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialog } from "../components/confirm-dialog/confirm-dialog.component";
import { map } from "rxjs/operators";

export interface IDeactivateComponent {
    canExit: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
    providedIn: "root",
})
export class UnsavedChangesGuard implements CanDeactivate<IDeactivateComponent> {
    constructor(public dialog: MatDialog) {}

    public canDeactivate(component: IDeactivateComponent): Observable<boolean> | Promise<boolean> | boolean {
        if (component.canExit && component.canExit()) {
            return of(true);
        }

        const dialogRef = this.dialog.open(ConfirmDialog, {
            data: {
                header: "Warning: There are unsaved changes",
                text: "You have unsaved changes on this page.  Are you sure you want to leave this page?",
            }
        });

        return dialogRef.afterClosed().pipe(map((result) => result === true));
    }
}
