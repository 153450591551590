import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CurrentEquipmentService } from 'src/app/services/current-equipment.service';
import { CoveredInsulatingGasService } from 'src/app/shared/generated/api/covered-insulating-gas.service';
import { DepartmentService } from 'src/app/shared/generated/api/department.service';
import { EquipmentStatusService } from 'src/app/shared/generated/api/equipment-status.service';
import { EquipmentTypeService } from 'src/app/shared/generated/api/equipment-type.service';
import { EquipmentService } from 'src/app/shared/generated/api/equipment.service';
import { ManufacturerService } from 'src/app/shared/generated/api/manufacturer.service';
import { SealTypeService } from 'src/app/shared/generated/api/seal-type.service';
import { CoveredInsulatingGasEnum } from 'src/app/shared/generated/enum/covered-insulating-gas-enum';
import { EquipmentStatusEnum } from 'src/app/shared/generated/enum/equipment-status-enum';
import { EquipmentTypeEnum } from 'src/app/shared/generated/enum/equipment-type-enum';
import { CoveredInsulatingGasDto } from 'src/app/shared/generated/model/covered-insulating-gas-dto';
import { DepartmentDto } from 'src/app/shared/generated/model/department-dto';
import { EquipmentStatusDto } from 'src/app/shared/generated/model/equipment-status-dto';
import { EquipmentTypeDto } from 'src/app/shared/generated/model/equipment-type-dto';
import { EquipmentUpsertDto } from 'src/app/shared/generated/model/equipment-upsert-dto';
import { ManufacturerDto } from 'src/app/shared/generated/model/manufacturer-dto';
import { SealTypeDto } from 'src/app/shared/generated/model/seal-type-dto';
import { ArrayHelpers } from 'src/app/shared/helpers/array-helpers';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'pog-equipment-form',
  templateUrl: './equipment-form.component.html',
  styleUrls: ['./equipment-form.component.scss']
})
export class EquipmentFormComponent implements OnInit {
  @ViewChild("equipmentForm", { read: NgForm }) form: NgForm;

  @Output() formSubmitted = new EventEmitter<any>();
  @Output() cancelEditModeChange = new EventEmitter<boolean>();

  @Input() equipmentID: number;
  @Input() equipment: EquipmentUpsertDto;
  @Input() editMode: boolean;

  equipmentUpsertDto: EquipmentUpsertDto;

  public equipmentTypes$: Observable<EquipmentTypeDto[]>;
  public sealTypes$: Observable<SealTypeDto[]>;
  public manufacturers$: Observable<ManufacturerDto[]>;
  public equipmentStatuses$: Observable<EquipmentStatusDto[]>;
  public coveredInsulatingGases$: Observable<CoveredInsulatingGasDto[]>;
  public departments$: Observable<DepartmentDto[]>;

  validNumericValueMap = new Map<string, any>();

  constructor(
    private equipmentTypeService: EquipmentTypeService,
    private sealTypeService: SealTypeService,
    private manufacturerService: ManufacturerService,
    private equipmentStatusService: EquipmentStatusService,
    private coveredInsulatingGasService: CoveredInsulatingGasService,
    private departmentService: DepartmentService,
    private currentEquipmentService: CurrentEquipmentService,
    private equipmentService: EquipmentService,
    private alertService: AlertService) {}

  ngOnInit(): void {
    this.equipmentTypes$ = this.equipmentTypeService.equipmentTypesGet();
    this.sealTypes$ = this.sealTypeService.sealTypesGet();
    this.manufacturers$ = this.manufacturerService.manufacturersGet()
      .pipe(map(manufacturers => ArrayHelpers.sortByProperty(manufacturers, 'ManufacturerDisplayName')));
    this.equipmentStatuses$ = this.equipmentStatusService.equipmentStasusesGet();
    this.coveredInsulatingGases$ = this.coveredInsulatingGasService.coveredInsulatingGasesGet();
    this.departments$ = this.departmentService.departmentsGet();
      if (this.equipment) {
          this.setForm();
      }
  }

  setForm() {
      this.equipmentUpsertDto = this.currentEquipmentService.createEquipmentDto(this.equipment);
      this.validNumericValueMap.set('VoltageCapacity', this.equipmentUpsertDto.VoltageCapacity);
      this.validNumericValueMap.set('NameplateCapacity', this.equipmentUpsertDto.NameplateCapacity);
  }

  saveForm(form: NgForm) {
    if (!!this.equipmentID) {
        this.equipmentService.equipmentEquipmentIDPut(this.equipmentID, this.equipmentUpsertDto)
            .subscribe((result) => {
            this.alertService.pushAlert(new Alert("The equipment was successfully updated.", AlertContext.Success), 5000);
            this.formSubmitted.emit(result);
        });
    } else {
      this.equipmentUpsertDto.CoveredInsulatingGasID = CoveredInsulatingGasEnum.SF6;
      this.equipmentUpsertDto.EquipmentStatusID = EquipmentStatusEnum.Active;
      this.equipmentService.equipmentPost(this.equipmentUpsertDto)
        .subscribe((result) => {
          this.formSubmitted.emit(result);
            this.alertService.pushAlert(new Alert("The equipment was successfully created.", AlertContext.Success), 5000);
        },
        () => {
            this.formSubmitted.emit(null);
        });
    }
  }

  cancelEditMode() {
      this.setForm();
      this.cancelEditModeChange.emit(true);
  }

  handleEquipmentStatusChange(equipmentStatusID: any){
    if (EquipmentStatusEnum.Active == equipmentStatusID) {
      this.equipmentUpsertDto.RetirementDate = null;
    }
  }

  isRetirementDateRequired() {
    return this.equipmentUpsertDto.EquipmentStatusID == EquipmentStatusEnum.Inactive;
  }

  isEquipmentTypeOtherRequired() {
    return this.equipmentUpsertDto.EquipmentTypeID == EquipmentTypeEnum.Other;
  }

  handleEquipmentTypeChange() {
    this.equipmentUpsertDto.EquipmentTypeOther = null;
  }

  validateNumberForProperty(event, property) {
    const value = event.target.value;
    var validNumber = new RegExp(/^\d*\.?\d*$/);
    if (validNumber.test(value)) {
        this.validNumericValueMap.set(property, value);
    }
      this.equipmentUpsertDto[property] = this.validNumericValueMap.get(property);
  }

  validateDecimalPlaces(property) {
    const existingValue = this.validNumericValueMap.get(property);
    if (!!existingValue) {
       const existingValueString = existingValue + "";
       this.validNumericValueMap.set(property, new Number(parseFloat(existingValueString).toFixed(2)));
    }    
    this.equipmentUpsertDto[property] = this.validNumericValueMap.get(property);
  }
}