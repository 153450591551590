import { forkJoin } from 'rxjs';
import { EquipmentService } from 'src/app/shared/generated/api/equipment.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CanisterService } from 'src/app/shared/generated/api/canister.service';
import { map } from 'rxjs/operators';

@Component({
    selector: 'pog-location-list',
    templateUrl: './location-list.component.html',
    styleUrls: ['./location-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationListComponent implements OnInit {
    public locations$: Observable<any>;

    constructor(
        private equipmentService: EquipmentService,
        private canisterService: CanisterService,
        private cdr: ChangeDetectorRef,
    ) { }

    ngOnInit(): void {
        this.locations$ = forkJoin([
            this.equipmentService.equipmentLocationsGet(),
            this.canisterService.canistersLocationsGet()
        ])
        .pipe(
            map(([equipment, canisters]) => {
                return {
                    equipment: equipment,
                    canisters: canisters
                };
            })
        );
    }

}
