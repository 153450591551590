<div class="detail-view">
    <div class="actions-bar">
        <esa-material-button *ngIf="canEdit()" type="primary" icon="edit" (click)="enableEditMode()"> </esa-material-button>
    </div>

    <ng-container *ngIf="equipment$ | async as e">
        <pog-equipment-location-form
            #updateLocationForm
            [equipment]="e"
            [location]="location"
            [editMode]="editMode"
            (formSubmitted)="save($event)"
            (cancelEditModeChange)="cancelEdit()"></pog-equipment-location-form>
    </ng-container>
</div>
