import { Component, Inject, Renderer2, ViewContainerRef } from '@angular/core';
import { environment } from '../environments/environment';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd, NavigationEnd, ActivationEnd } from '@angular/router';
import { BusyService } from './shared/services';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { UserDto } from './shared/generated/model/user-dto';
import { AuthenticationService } from './services/authentication.service';
import { RoleEnum } from './shared/generated/enum/role-enum';
import { UtilityFunctionsService } from './services/utility-functions.service';

declare var require: any

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    userClaimsUpsertStarted = false;
    isIframe = false;    
    ignoreSessionTerminated = false;

    private currentUser: UserDto;
    public currentYear: number = new Date().getFullYear();
    
    private userRoleClassName: string;

    constructor(
        @Inject(DOCUMENT) private _document: Document,
        private router: Router,
        private busyService: BusyService,
        private titleService: Title,
        private renderer: Renderer2,
        private authenticationService: AuthenticationService,
        private utilityFunctionService: UtilityFunctionsService,
        public viewRef: ViewContainerRef
    ) {
    }

    ngOnInit() {
        this.isIframe = window !== window.parent && !window.opener;

        const environmentClassName = environment.production ? 'env-prod' : environment.staging ? 'env-qa' : 'env-dev';
        this.renderer.addClass(this._document.body, environmentClassName);

        this.authenticationService.currentUserSetObservable.subscribe(currentUser => {
            this.currentUser = currentUser;

            if (this.userRoleClassName) {
                this.renderer.removeClass(this._document.body, this.userRoleClassName);
            }

            const role = RoleEnum[this.currentUser.Role.RoleID]
            if (role) {
                this.userRoleClassName = 'role-' + this.utilityFunctionService.stringToKebabCase(role);
                this.renderer.addClass(this._document.body, this.userRoleClassName);
            }
        });
        
        this.router.events.subscribe((event: any) => {
            if (event instanceof RouteConfigLoadStart) { // lazy loaded route started
                this.busyService.setBusy(true);
            } else if (event instanceof RouteConfigLoadEnd) { // lazy loaded route ended
                this.busyService.setBusy(false);
            } else if (event instanceof NavigationEnd) {
                window.scrollTo(0, 0);
            }
        });

        this.titleService.setTitle(`${environment.platformLongName}`)
        this.setAppFavicon();
    }

    setAppFavicon() {
        this._document.getElementById('appFavicon').setAttribute('href', "assets/main/favicons/favicon.ico");
    }
}
