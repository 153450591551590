import { Component, OnInit, Input, ViewChild, AfterViewChecked, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { CustomRichTextService } from 'src/app/shared/generated/api/custom-rich-text.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { AlertService } from '../../services/alert.service';
import { Alert } from '../../models/alert';
import { AlertContext } from '../../models/enums/alert-context.enum';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CustomRichTextDto } from 'src/app/shared/generated/model/custom-rich-text-dto';
import { PermissionEnum } from '../../generated/enum/permission-enum';
import { RightsEnum } from '../../models/enums/rights.enum';
import TinyMCEHelpers from '../../helpers/tiny-mce-helpers';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { Subscription } from 'rxjs';

@Component({
  selector: 'custom-rich-text',
  templateUrl: './custom-rich-text.component.html',
  styleUrls: ['./custom-rich-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomRichTextComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('tinyMceEditor') tinyMceEditor : EditorComponent;
  public tinyMceConfig: object;

  @Input() customRichTextTypeID: number;
  public customRichTextContent: SafeHtml;
  public isLoading: boolean = true;
  public isEditing: boolean = false;
  public isEmptyContent: boolean = false;
  public editedContent: string;

  currentUser: UserDto;
  userSubscription: Subscription;
  customRichTextSubscription: Subscription;
  customRichTextResponse: Subscription;

  constructor(
    private customRichTextService: CustomRichTextService,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef) { 
  }

  ngAfterViewChecked() {
    // viewChild is updated after the view has been checked
    this.initalizeEditor();
  }

  initalizeEditor() {
    if (!this.isLoading && this.isEditing) {
      this.tinyMceConfig = TinyMCEHelpers.DefaultInitConfig(
        this.tinyMceEditor
      );
    }
  }


  ngOnInit() {
    this.userSubscription = this.authenticationService.getCurrentUser().subscribe(currentUser => {
      this.currentUser = currentUser;
      this.cdr.markForCheck();
    });

    this.customRichTextSubscription = this.customRichTextService.customRichTextsCustomRichTextTypeIDGet(this.customRichTextTypeID).subscribe(x => {
      this.customRichTextContent = this.sanitizer.bypassSecurityTrustHtml(x.CustomRichTextContent);
      this.isEmptyContent = x.IsEmptyContent;
      this.editedContent = x.CustomRichTextContent;
      this.isLoading = false;
      this.cdr.markForCheck();
    });
  }

  public showEditButton(): boolean {
    return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.CustomRichTextRights, RightsEnum.Update);
  }

  public enterEdit(): void {
    this.isEditing = true;
    this.cdr.markForCheck();
  }

  public cancelEdit(): void {
    this.isEditing = false;
    this.cdr.markForCheck();
  }

  public saveEdit(): void {
    this.isEditing = false;
    this.isLoading = true;
    const updateDto = new CustomRichTextDto({ CustomRichTextContent: this.editedContent });
    this.customRichTextResponse = this.customRichTextService.customRichTextsCustomRichTextTypeIDPut(this.customRichTextTypeID, updateDto).subscribe(x => {
      this.customRichTextContent = this.sanitizer.bypassSecurityTrustHtml(x.CustomRichTextContent);
      this.editedContent = x.CustomRichTextContent;
      this.isLoading = false;
      this.cdr.markForCheck();
    }, error => {
      this.isLoading = false;
      this.alertService.pushAlert(new Alert("There was an error updating the rich text content", AlertContext.Danger, true));
      this.cdr.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.cdr.detach();
    this.userSubscription?.unsubscribe();
    this.customRichTextSubscription?.unsubscribe();
    this.customRichTextResponse?.unsubscribe();
  }
}
