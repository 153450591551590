<div class="site">
    <div class="header-wrapper">
        <ng-container *ngIf="equipment$ | async as e">
            <div class="equipment-title">
                <h3>EQUIPMENT DETAILS</h3>
                <h1>{{e.SerialNumber}}</h1>
            </div>
        </ng-container>
    </div>

    <div class="dashboard">
        <aside class="sidebar">
            <ul class="sidebar-nav">
                <li class="sidebar-item" *ngFor="let item of equipmentSideNav">
                    <a [routerLink]="item.Link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidebar-link">{{ item.Name }}</a>
                </li>
            </ul>
        </aside>
        <div class="main">
            <router-outlet #manageOutlet="outlet"></router-outlet>
        </div>
    </div>
</div>
