<div class="list-view">
    <div class="page-header">
        <h1 class="page-title">Labels and Definitions</h1>
    
        <div class="copy copy-2">
            <custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
        </div>
    </div>

    <div class="flex-end pb-2">
        <pog-clear-grid-filters-button class="m-2" [grid]="fieldDefinitionsGrid"></pog-clear-grid-filters-button>
    </div>

    <div class="table-responsive">
        <ag-grid-angular 
            #fieldDefinitionsGrid 
            style="width: 100%; height: 100%;" 
            class="ag-theme-balham" 
            [rowData]="rowData" 
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef" 
            [suppressMenuHide]="true"
            (gridReady)="onGridReady($event)">
        </ag-grid-angular>
    </div>
</div>