import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from "@angular/core";
import { FileService } from "../../services/file/file.service";
import { Subscription } from "rxjs";
import { finalize } from "rxjs/operators";

@Component({
    selector: "pog-file-uploader",
    templateUrl: "./file-uploader.component.html",
    styleUrls: ["./file-uploader.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploaderComponent implements OnDestroy {
    @Input() fileRoute: string;
    @Input() multiple: boolean = true;
    @Input() showPreviewForPhotos: boolean = false;
    @Input() immediate: boolean = true; //this one means that the file is uploaded immediately, if set to false, you need to call uploadFiles() directly.
    @Input() accept: string = "";
    @Output() hasFilesToUpload: EventEmitter<any> = new EventEmitter<any>();
    @Output() fileUploadStarted: EventEmitter<any> = new EventEmitter<any>();
    @Output() fileUploadSuccess: EventEmitter<any> = new EventEmitter<any>();
    @Output() fileUploadFailure: EventEmitter<any> = new EventEmitter<any>();

    public filesToUpload: File[] = [];
    public photoUrl = [];
    public isLoading: boolean = false;

    uploadSubscription: Subscription;

    constructor(private fileService: FileService, private cdr: ChangeDetectorRef) {}

    onFilesSelected(files: FileList) {
        for (let i = 0; i < files.length; i++) {
            this.filesToUpload.push(files.item(i));
            var photoUrl = this.photoUrl;
            var cdr = this.cdr;
            var reader = new FileReader();
            reader.onload = function(){
              var dataURL = reader.result;
              photoUrl.push(dataURL);
              cdr.markForCheck();
            };
            reader.readAsDataURL(files[i]);
        }
        
        if (this.filesToUpload.length) {
            this.hasFilesToUpload.emit(true);
        }

        this.cdr.markForCheck();
    }

    spliceFiles(index) {
        this.filesToUpload.splice(index, 1);

        if (!this.filesToUpload.length) {
            this.hasFilesToUpload.emit(false);
        }
    }

    uploadFiles() {
        this.isLoading = true;
        this.fileUploadStarted.emit(true);
        this.uploadSubscription = this.fileService.uploadFiles(this.fileRoute, this.filesToUpload)
        .pipe(finalize(() => {
            this.isLoading = false;
        }))
        .subscribe({
            next: (result) => {
            },
            error: (err) => {
                this.fileUploadFailure.emit(err);
            },
            complete: () => {
                this.fileUploadSuccess.emit(true);
                this.filesToUpload = [];
                this.hasFilesToUpload.emit(false);
                this.cdr.markForCheck();
            }   
        });
    }

    isPhoto(file): boolean {
        if (file){
            if (file.type.includes("image/")) {
                return true;
            }
        }
        return false;
    }

    ngOnDestroy(): void {
        this.uploadSubscription?.unsubscribe();
    }
}