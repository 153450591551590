<form class="form" #yearEndWeightForm="ngForm">
    <div class="grid-12">
        <div class="g-col-6">
            <esa-material-ff
                name="ReportingYearID"
                [editMode]="editMode"
                [required]="true"
                label="Reporting Year"
                type="dropdown"
                [options]="years"
                optionLabel="YearName"
                optionValue="YearID"
                [(ngModel)]="yearEndWeightUpsertDto.ReportingYearID"
            >
            </esa-material-ff>
        </div>
        <div class="g-col-6">
            <esa-material-ff
                name="WeightDate"
                [editMode]="editMode"
                label="Date Weighed"
                type="date"
                [required]="true"
                [(ngModel)]="yearEndWeightUpsertDto.WeightDate"
            >
            </esa-material-ff>
        </div>
        <div class="g-col-6">
            <esa-material-ff
                name="ReasonForMeasurementID"
                [editMode]="editMode"
                [required]="true"
                label="Reason for Measuring"
                type="dropdown"
                [options]="reasonsForMeasurement"
                optionLabel="ReasonForMeasurementDisplayName"
                optionValue="ReasonForMeasurementID"
                [(ngModel)]="yearEndWeightUpsertDto.ReasonForMeasurementID"
            >
            </esa-material-ff>
        </div>
    </div>

    <ng-container *ngIf="editMode && tareWeight">
        <div class="grid-12" style="background-color:lightgray; padding:1rem; border-radius: 8px; margin-bottom: 1rem;">
            <div class="g-col-12">
                <div class="calculator-header">Amount of Gas Calculator</div>
            </div>
            <div class="g-col-6">
                <esa-material-ff
                    name="CanisterWeightPounds"
                    [editMode]="editMode"
                    label="Canister Weight (lbs)"
                    type="text"
                    [(ngModel)]="canisterWeight.Pounds"
                    (focusout)="onBlur($event)"
                >
                </esa-material-ff>
            </div>
            <div class="g-col-6">
                <esa-material-ff
                    name="CanisterWeightOunces"
                    [editMode]="editMode"
                    label="Canister Weight (ozs)"
                    type="text"
                    [(ngModel)]="canisterWeight.Ounces"
                    (focusout)="onBlur($event)"
                >
                </esa-material-ff>
            </div>
            <div class="g-col-6">
                <esa-material-ff
                    name="TareWeight"
                    [editMode]="false"
                    label="Tare Weight (lbs)"
                    type="text"
                    [(ngModel)]="tareWeight"
                >
                </esa-material-ff>
            </div>
            <div class="g-col-6">
                <esa-material-ff
                    name="CalculatedAmount"
                    [editMode]="false"
                    label="Calculated Amount of Gas (lbs)"
                    type="text"
                    [(ngModel)]="canisterWeight.AmountOfGas"
                >
                </esa-material-ff>
            </div>
        </div>
    </ng-container>

    <div class="grid-12">
        <div class="g-col-6">
            <esa-material-ff
                name="Weight"
                [editMode]="editMode"
                [required]="true"
                label="Amount of Gas (lb)"
                type="text"
                [(ngModel)]="yearEndWeightUpsertDto.Weight"
            >
            </esa-material-ff>
        </div>
        <div class="g-col-12">
            <esa-material-ff 
                name="Notes" 
                [editMode]="editMode" 
                [required]="false" 
                label="Notes"
                type="textarea" 
                [(ngModel)]="yearEndWeightUpsertDto.Notes" 
                >
            </esa-material-ff>
        </div>  
    
        <div class="g-col-12" *ngIf="file">
            <img [src]="fileUrl" width="200" height="200" style="display:inline; max-width: inherit;" />
            <esa-material-button type="primary"icon="delete" (click)="deleteFile()"></esa-material-button>
            <esa-material-button type="secondary" icon="download" (click)="downloadFile()"></esa-material-button>
        </div>
        
        <div class="g-col-12">
            <pog-file-uploader
                #uploader
                [multiple]="false"
                [fileRoute]="fileRoute"
                [showPreviewForPhotos]="true"
                [immediate]="false"
                accept=".png,.jpg,.jpeg"
                (fileUploadSuccess)="uploadSuccess($event)"
                *ngIf="editMode && !file">
            </pog-file-uploader>
        </div>
    
        <!-- (hasFilesToUpload)="hasFilesToUpload($event)"
        (fileUploadStarted)="fileUploadStarted($event)" 
        (fileUploadSuccess)="fileUploadSuccess($event)"  -->
    
        <div class="required-note g-col-12">
            <i class="fas fa-bolt"></i> Required field
        </div>
    
        <div class="g-col-12 actions-bar" *ngIf="editMode" style="margin-top: 1rem">
            <esa-material-button
                style="margin-right: 1rem"
                type="primary"
                label="Save"
                icon="save"
                [tooltip]="
                    !yearEndWeightForm.form.valid
                        ? 'You are missing required fields.'
                        : ''
                "
                (click)="saveForm(form)"
                [disabled]="!yearEndWeightForm.form.valid"
            >
            </esa-material-button>
    
            <esa-material-button
                type="secondary"
                label="Cancel"
                icon="cancel"
                (click)="cancelEditMode()"
            >
            </esa-material-button>
        </div>
    </div>
</form>
