<div class="detail-view">
    <div class="actions-bar">
        <esa-material-button *ngIf="canEdit()" type="clear" icon="edit" (click)="enableEditMode()"> </esa-material-button>
    </div>

    <ng-container *ngIf="project$ | async as p">
        <pog-project-form
            #updateProjectForm
            [projectID]="p.ProjectID"
            [project]="p"
            [editMode]="editMode"
            (formSubmitted)="save($event)"
            (cancelEditModeChange)="cancelEdit()"></pog-project-form>
    </ng-container>
</div>
