import { Component, OnInit} from '@angular/core';

@Component({
  selector: 'pog-dashboard-manage',
  templateUrl: './dashboard-manage.component.html',
  styleUrls: ['./dashboard-manage.component.scss']
})
export class DashboardManageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
