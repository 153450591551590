import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SystemInfoDto } from '../generated/model/system-info-dto';
import { SystemInfoService } from '../generated/api/system-info.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'footer-nav',
  templateUrl: './footer-nav.component.html',
  styleUrls: ['./footer-nav.component.scss']
})
export class FooterNavComponent implements OnInit, OnDestroy {
  public currentYear: number = new Date().getFullYear();
  systemInfoSubscription: Subscription;
  systemInfo: SystemInfoDto;
  constructor(
    public authenticationService: AuthenticationService,
    public systemInfoService: SystemInfoService
  ) { }

  ngOnInit(): void {
    this.systemInfoSubscription = this.systemInfoService.getSystemInfo().subscribe((result) => {
      this.systemInfo = result;
    });
  }

  ngOnDestroy() {
    this.systemInfoSubscription?.unsubscribe();
  }
}
