import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { CurrentCanisterService } from 'src/app/services/current-canister.service';
import { CanisterStatusService } from 'src/app/shared/generated/api/canister-status.service';
import { CanisterService } from 'src/app/shared/generated/api/canister.service';
import { CoveredInsulatingGasService } from 'src/app/shared/generated/api/covered-insulating-gas.service';
import { PropertyOfGieStatusService } from 'src/app/shared/generated/api/property-of-gie-status.service';
import { VesselTypeService } from 'src/app/shared/generated/api/vessel-type.service';
import { CanisterStatusEnum } from 'src/app/shared/generated/enum/canister-status-enum';
import { CoveredInsulatingGasEnum } from 'src/app/shared/generated/enum/covered-insulating-gas-enum';
import { CanisterUpsertDto } from 'src/app/shared/generated/model/canister-upsert-dto';
import { CoveredInsulatingGasDto } from 'src/app/shared/generated/model/covered-insulating-gas-dto';
import { CanisterStatusDto, PropertyOfGieStatusDto, VesselTypeDto } from 'src/app/shared/generated/model/models';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'pog-canister-form',
  templateUrl: './canister-form.component.html',
  styleUrls: ['./canister-form.component.scss']
})
export class CanisterFormComponent implements OnInit {
  @ViewChild("canisterForm", { read: NgForm }) form: NgForm;

  @Output() formSubmitted = new EventEmitter<any>();
  @Output() cancelEditModeChange = new EventEmitter<boolean>();

  @Input() canisterID: number;
  @Input() canister: CanisterUpsertDto;
  @Input() editMode: boolean;

  canisterUpsertDto: CanisterUpsertDto;

  public vesselTypes$: Observable<VesselTypeDto[]>;
  public propertyOfGieStatuses$: Observable<PropertyOfGieStatusDto[]>;
  public coveredInsulatingGases$: Observable<CoveredInsulatingGasDto[]>;
  public canisterStatuses$: Observable<CanisterStatusDto[]>;

  constructor(
    private vesselTypeService: VesselTypeService,
    private propertyOfGieStatusService: PropertyOfGieStatusService,
    private coveredInsulatingGasService: CoveredInsulatingGasService,
    private canisterStatusService: CanisterStatusService,
    private currentCanisterService: CurrentCanisterService,
    private canisterService: CanisterService,
    private alertService: AlertService) {}

  ngOnInit(): void {
    this.vesselTypes$ = this.vesselTypeService.vesselTypesGet();
    this.propertyOfGieStatuses$ = this.propertyOfGieStatusService.propertyOfGieStasusesGet();
    this.coveredInsulatingGases$ = this.coveredInsulatingGasService.coveredInsulatingGasesGet();
    this.canisterStatuses$ = this.canisterStatusService.canisterStasusesGet();
    if (this.canister) {
        this.setForm();
    }
  }

  setForm() {
    this.canisterUpsertDto = this.currentCanisterService.createCanisterDto(this.canister);
    if (!this.canisterUpsertDto.CoveredInsulatingGasID) {
      this.canisterUpsertDto.CoveredInsulatingGasID = CoveredInsulatingGasEnum.SF6;
    }
  }

  saveForm(form: NgForm) {
    if (!!this.canisterID) {
        this.canisterService.canistersCanisterIDPut(this.canisterID, this.canisterUpsertDto)
            .subscribe((result) => {
            this.alertService.pushAlert(new Alert("The canister was successfully updated.", AlertContext.Success), 5000);
            this.formSubmitted.emit(result);
        });
    } else {
      this.canisterService.canistersPost(this.canisterUpsertDto)
        .subscribe((result) => {
          this.formSubmitted.emit(result);
            this.alertService.pushAlert(new Alert("The canister was successfully created.", AlertContext.Success), 5000);
        },
        () => {
            this.formSubmitted.emit(null);
        });
    }
  }

  cancelEditMode() {
      this.setForm();
      this.cancelEditModeChange.emit(true);
  }

  handleCanisterStatusChange(canisterStatusID: any){
    if (CanisterStatusEnum.Active == canisterStatusID) {
      this.canisterUpsertDto.RetirementDate = null;
    }
  }

  isRetirementDateRequired() {
    return this.canisterUpsertDto.CanisterStatusID == CanisterStatusEnum.Inactive;
  }

}