<div class="detail-view">
    <div class="actions-bar">
        <esa-material-button *ngIf="canAdd()" type="primary" icon="add" (click)="addYearEndWeight()"> </esa-material-button>
    </div>

    <!-- <mat-accordion> -->
        <mat-expansion-panel *ngIf="newYearEndWeight" expanded="true" disabled="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    New Record
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>
            
            <pog-year-end-weight-form
                #createNewYearEndWeightForm
                [canisterID]="canister.CanisterID"
                [tareWeight]="canister.TareWeight"
                [yearEndWeight]="newYearEndWeight"
                [years]="years"
                [reasonsForMeasurement]="reasonsForMeasurement"
                [editMode]="editMode"
                (formSubmitted)="save($event)"
                (cancelEditModeChange)="cancelEdit()"></pog-year-end-weight-form>
        </mat-expansion-panel>

        <mat-expansion-panel *ngFor="let item of yearEndWeights">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ item.ReportingYear.YearName }} {{editingID == item.YearEndWeightID ? "(Editing)" : ""}}
                </mat-panel-title>
                <mat-panel-description>
                    Amount of Gas: {{ item.Weight }}lbs. ({{ item.WeightDate | date }})
                </mat-panel-description>
            </mat-expansion-panel-header>
            
            <div class="actions-bar">
                <esa-material-button *ngIf="canEdit()" type="primary" icon="edit" (click)="enableEditMode(item.YearEndWeightID)"> </esa-material-button>
            </div>
            <pog-year-end-weight-form
                #createNewYearEndWeightForm
                [yearEndWeightID]="item.YearEndWeightID"
                [canisterID]="canister.CanisterID"
                [tareWeight]="canister.TareWeight"
                [yearEndWeight]="item"
                [years]="years"
                [reasonsForMeasurement]="reasonsForMeasurement"
                [editMode]="editMode && editingID == item.YearEndWeightID"
                (formSubmitted)="save($event)"
                (cancelEditModeChange)="cancelEdit()"></pog-year-end-weight-form>
        </mat-expansion-panel>
    <!-- </mat-accordion> -->
</div>
