import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "pog-custom-form-label",
    templateUrl: "./custom-form-label.component.html",
    styleUrls: ["./custom-form-label.component.scss"],
})
export class CustomFormLabelComponent implements OnInit {
    @Input() label!: string;
    @Input() fieldDefinitionType!: string;
    @Input() popperOptions!: any;
    @Input() required!: boolean;

    constructor() {}

    ngOnInit(): void {}
}