/**
 * Pog.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ReasonForMeasurementDto } from '././reason-for-measurement-dto';
import { UserDto } from '././user-dto';
import { CanisterDto } from '././canister-dto';
import { YearDto } from '././year-dto';

export class YearEndWeightDto { 
    YearEndWeightID?: number;
    Canister?: CanisterDto;
    ReportingYear?: YearDto;
    WeightDate?: string;
    ReasonForMeasurement?: ReasonForMeasurementDto;
    Weight?: number;
    Notes?: string;
    CreateDate?: string;
    CreateUser?: UserDto;
    UpdateDate?: string;
    UpdateUser?: UserDto;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}
