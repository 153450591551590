import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent, UnauthenticatedComponent, SubscriptionInsufficientComponent } from './shared/pages';
import { UserListComponent } from './pages/user-list/user-list.component';
import { HomeIndexComponent } from './pages/home/home-index/home-index.component';
import { UserDetailComponent } from './pages/user-detail/user-detail.component';
import { UserEditComponent } from './pages/user-edit/user-edit.component';
import { LoginCallbackComponent } from './pages/login-callback/login-callback.component';
import { HelpComponent } from './pages/help/help.component';
import { CreateUserCallbackComponent } from './pages/create-user-callback/create-user-callback.component';
import { FieldDefinitionListComponent } from './pages/field-definition-list/field-definition-list.component';
import { FieldDefinitionEditComponent } from './pages/field-definition-edit/field-definition-edit.component';
import { CustomPageListComponent } from './pages/custom-page-list/custom-page-list.component';
import { CustomPageDetailComponent } from './pages/custom-page-detail/custom-page-detail.component';
import { CustomPageEditPropertiesComponent } from './pages/custom-page-edit-properties/custom-page-edit-properties.component';
import { MsalGuard } from '@azure/msal-angular';
import { UnsavedChangesGuard } from './shared/guards/unsaved-changes-guard';
import { DashboardManageComponent } from './pages/dashboards/dashboard-manage/dashboard-manage.component';
import { UserInviteComponent } from './pages/user-invite/user-invite.component';
import { ProjectListComponent } from './pages/project/project-list/project-list.component';
import { ProjectCreateComponent } from './pages/project/project-create/project-create.component';
import { ProjectDetailComponent } from './pages/project/project-detail/project-detail.component';
import { ProjectDetailSummaryComponent } from './pages/project/project-detail/project-detail-summary/project-detail-summary.component';
import { ProjectFileListComponent } from "./pages/project/project-detail/project-file-list/project-file-list.component";
import { EquipmentListComponent } from './pages/equipment/equipment-list/equipment-list.component';
import { EquipmentCreateComponent } from './pages/equipment/equipment-create/equipment-create.component';
import { EquipmentDetailSummaryComponent } from './pages/equipment/equipment-detail/equipment-detail-summary/equipment-detail-summary.component';
import { EquipmentDetailComponent } from './pages/equipment/equipment-detail/equipment-detail.component';
import { CanisterListComponent } from './pages/canister/canister-list/canister-list.component';
import { CanisterCreateComponent } from './pages/canister/canister-create/canister-create.component';
import { CanisterDetailSummaryComponent } from './pages/canister/canister-detail/canister-detail-summary/canister-detail-summary.component';
import { CanisterDetailComponent } from './pages/canister/canister-detail/canister-detail.component';
import { CanisterYearEndWeightListComponent } from './pages/canister/canister-detail/canister-year-end-weight-list/canister-year-end-weight-list.component';
import { ReportEquipmentGenerateComponent } from './pages/report/report-equipment-generate/report-equipment-generate.component';
import { ReportCanisterGenerateComponent } from './pages/report/report-canister-generate/report-canister-generate.component';
import { CanisterFileListComponent } from './pages/canister/canister-detail/canister-file-list/canister-file-list.component';
import { EquipmentFileListComponent } from './pages/equipment/equipment-detail/equipment-file-list/equipment-file-list.component';
import { EquipmentLocationComponent } from './pages/equipment/equipment-detail/equipment-location/equipment-location.component';
import { CanisterLocationComponent } from './pages/canister/canister-detail/canister-location/canister-location.component';
import { LocationListComponent } from './pages/location/location-list/location-list.component';

const routes: Routes = [

  { path: "custom-pages/:vanity-url", component: CustomPageDetailComponent, canDeactivate: [UnsavedChangesGuard] },

  { path: "manage", component: DashboardManageComponent, canActivate: [MsalGuard], children: [
    { path: "users", component: UserListComponent },
    { path: "users/invite", component: UserInviteComponent },
    { path: "users/:id", component: UserDetailComponent },
    { path: "users/:id/edit", component: UserEditComponent },
    { path: "custom-pages", component: CustomPageListComponent, canActivate: [MsalGuard] },
    { path: "custom-pages/create", component: CustomPageEditPropertiesComponent, data: { create: true }, canDeactivate: [UnsavedChangesGuard] },
    { path: "custom-pages/edit-properties/:vanity-url", component: CustomPageEditPropertiesComponent, data: { create: false }, canDeactivate: [UnsavedChangesGuard] },
    { path: "labels-and-definitions/:id", component: FieldDefinitionEditComponent, canDeactivate: [UnsavedChangesGuard] },
    { path: "labels-and-definitions", component: FieldDefinitionListComponent },
  ] },
  { path: "projects", component: ProjectListComponent, canActivate: [MsalGuard] },
  {
    path: "projects/create", component: ProjectCreateComponent,
    canActivate: [MsalGuard],
    canDeactivate: [UnsavedChangesGuard]
  },
  {
    path: "projects/:id",
    component: ProjectDetailComponent,
    canActivate: [MsalGuard],
    children: [
      {
        path: "",
        component: ProjectDetailSummaryComponent,
        canActivate: [MsalGuard],
        canDeactivate: [UnsavedChangesGuard]
      },
      {
        path: "file-list",
        component: ProjectFileListComponent,
        canActivate: [MsalGuard],
        canDeactivate: [UnsavedChangesGuard]
      }
    ]
  },
  { path: "equipment", component: EquipmentListComponent, canActivate: [MsalGuard] },
  {
    path: "equipment/create", component: EquipmentCreateComponent,
    canActivate: [MsalGuard],
    canDeactivate: [UnsavedChangesGuard]
  },
  {
    path: "equipment/:id",
    component: EquipmentDetailComponent,
    canActivate: [MsalGuard],
    children: [
      {
        path: "",
        component: EquipmentDetailSummaryComponent,
        canActivate: [MsalGuard],
        canDeactivate: [UnsavedChangesGuard]
      },
      {
        path: "file-list",
        component: EquipmentFileListComponent,
        canActivate: [MsalGuard],
        canDeactivate: [UnsavedChangesGuard]
      },
      {
        path: "location",
        component: EquipmentLocationComponent,
        canActivate: [MsalGuard],
        canDeactivate: [UnsavedChangesGuard]
      }
    ]
  },
  { path: "canisters", component: CanisterListComponent, canActivate: [MsalGuard] },
  {
    path: "canisters/create", component: CanisterCreateComponent,
    canActivate: [MsalGuard],
    canDeactivate: [UnsavedChangesGuard]
  },
  {
    path: "canisters/:id",
    component: CanisterDetailComponent,
    canActivate: [MsalGuard],
    children: [
      {
        path: "",
        component: CanisterDetailSummaryComponent,
        canActivate: [MsalGuard],
        canDeactivate: [UnsavedChangesGuard]
      },
      {
        path: "location",
        component: CanisterLocationComponent,
        canActivate: [MsalGuard],
        canDeactivate: [UnsavedChangesGuard]
      },
      {
        path: "year-end-weight-list",
        component: CanisterYearEndWeightListComponent,
        canActivate: [MsalGuard],
        canDeactivate: [UnsavedChangesGuard]
      },
      {
        path: "file-list",
        component: CanisterFileListComponent,
        canActivate: [MsalGuard],
        canDeactivate: [UnsavedChangesGuard]
      }
    ]
  },
  { path: "map-explorer", component: LocationListComponent, canActivate: [MsalGuard] },
  {
    path: "reports/canisters", component: ReportCanisterGenerateComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "reports/equipment", component: ReportEquipmentGenerateComponent,
    canActivate: [MsalGuard],
  },
  { path: "help", component: HelpComponent },
  { path: "create-user-callback", component: CreateUserCallbackComponent },
  { path: "not-found", component: NotFoundComponent },
  { path: 'subscription-insufficient', component: SubscriptionInsufficientComponent },
  { path: 'unauthenticated', component: UnauthenticatedComponent },
  { path: "signin-oidc", component: LoginCallbackComponent },
  { path: "", component: HomeIndexComponent},
  { path: '**', component: NotFoundComponent }
];

const isIframe = window !== window.parent && !window.opener;
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    relativeLinkResolution: 'legacy',
    // Don't perform initial navigation in iframes
    initialNavigation: !isIframe ? 'enabledBlocking' : 'disabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
