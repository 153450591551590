//  IMPORTANT:
//  This file is generated. Your changes will be lost.
//  Source Table: [dbo].[FieldDefinitionType]

export enum FieldDefinitionTypeEnum {
  Name = 1,
  ProjectName = 2,
  ProjectDate = 3,
  Description = 4,
  SerialNumber = 5,
  EquipmentType = 6,
  EquipmentTypeOther = 7,
  SealType = 8,
  Manufacturer = 9,
  ManufactureYear = 10,
  VoltageCapacity = 11,
  NameplateCapacity = 12,
  CoveredInsulatingGas = 13,
  ContactName = 14,
  ContactEmail = 15,
  ContactPhone = 16,
  EquipmentStatus = 17,
  RetirementDate = 18,
  Department = 19,
  GasTransferredOutAmount = 20,
  CanisterStatus = 21,
  GasContainerOrGasCartID = 22
}
