<div class="dashboard">
    <aside class="sidebar">
        <div class="sidebar-header">
            <h5 class="sidebar-title"><a routerLink="/manage">Manage Dashboard</a></h5>
        </div>

        <ul class="sidebar-nav">
            <li class="sidebar-item"><a [routerLink]="['users']" routerLinkActive="active" class="sidebar-link"><i class="fa fa-user"></i>Users</a></li>
            <li class="sidebar-item"><a [routerLink]="['labels-and-definitions']" routerLinkActive="active" class="sidebar-link"><i class="fa fa-list-ul"></i>Labels & Definitions</a></li>
            <li class="sidebar-item"><a [routerLink]="['custom-pages']" routerLinkActive="active" class="sidebar-link"><i class="fa fa-file-alt"></i>Custom Pages</a></li>
        </ul>
    </aside>

    <main class="main">
        <div [hidden]="!manageOutlet.isActivated">
            <router-outlet #manageOutlet="outlet"></router-outlet>
        </div>
        <div [hidden]="manageOutlet.isActivated">
            <div class="page-header">
                <h1 class="page-title">Manage Dashboard</h1>
            </div>
        </div>
    </main>
</div>