import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PermissionEnum } from '../shared/generated/enum/permission-enum';
import { EquipmentDto } from '../shared/generated/model/equipment-dto';
import { EquipmentUpsertDto } from '../shared/generated/model/equipment-upsert-dto';
import { UserDto } from '../shared/generated/model/user-dto';
import { RightsEnum } from '../shared/models/enums/rights.enum';
import { AuthenticationService } from './authentication.service';
import { EquipmentLocationDto } from '../shared/generated/model/equipment-location-dto';

@Injectable({
  providedIn: 'root'
})
export class CurrentEquipmentService {
  currentEquipment$ = new BehaviorSubject<EquipmentDto>(null);

  constructor(private authenticationService: AuthenticationService) {}

  setCurrentEquipment(equipment: EquipmentDto): void {
      this.currentEquipment$.next(equipment);
  }

  getCurrentEquipment(): Observable<any> {
      return this.currentEquipment$.asObservable();
  }

  createEquipmentDto(equipment: EquipmentDto) {
      const equipmentDto = new EquipmentUpsertDto({
          SerialNumber: equipment?.SerialNumber,
          EquipmentTypeID: equipment?.EquipmentType?.EquipmentTypeID,
          EquipmentTypeOther: equipment?.EquipmentTypeOther,
          SealTypeID: equipment?.SealType?.SealTypeID,
          ManufacturerID: equipment?.Manufacturer?.ManufacturerID,
          ManufactureYear: equipment?.ManufactureYear,
          VoltageCapacity: equipment?.VoltageCapacity,
          NameplateCapacity: equipment?.NameplateCapacity,
          CoveredInsulatingGasID: equipment?.CoveredInsulatingGas?.CoveredInsulatingGasID,
          ContactName: equipment?.ContactName,
          ContactEmail: equipment?.ContactEmail,
          ContactPhone: equipment?.ContactPhone,
          EquipmentStatusID: equipment?.EquipmentStatus?.EquipmentStatusID,
          RetirementDate: equipment?.RetirementDate ? new Date(equipment.RetirementDate) : null,
          DepartmentID: equipment?.Department?.DepartmentID,
          GasTransferredOutAmount: equipment?.GasTransferredOutAmount,
          Address: equipment?.Address,
      });
      return equipmentDto;
  }

  createEquipmentLocationDto(equipment: EquipmentDto) {
      const equipmentDto = new EquipmentLocationDto({
          Latitude: equipment?.Latitude,
          Longitude: equipment?.Longitude
      });
      return equipmentDto;
  }

  canEditCurrentEquipment(currentUser: UserDto, equipment: EquipmentDto): boolean {
      return this.authenticationService.hasPermission(currentUser, PermissionEnum.EquipmentRights, RightsEnum.Update);
  }
}
