import {
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as L from 'leaflet'
import '../../../../../node_modules/leaflet.fullscreen/Control.FullScreen.js';
import GestureHandling from 'leaflet-gesture-handling';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { EquipmentLocationDto } from 'src/app/shared/generated/model/equipment-location-dto';
import { CanisterLocationDto } from 'src/app/shared/generated/model/models';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { PORT_OF_OAKLAND_EXTENT } from 'src/app/shared/models/pog-geospatial';

@Directive()
export abstract class LocationFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() location: EquipmentLocationDto | CanisterLocationDto;
  @Input() public editMode = false;
  @Input() public showLatLngDetails = true;

  @Output() formSubmitted = new EventEmitter<any>();
  @Output() cancelEditModeChange = new EventEmitter<boolean>();

  public mapID: string = 'mapID';
  public map: L.Map;
  public layerControl: L.Control.Layers;
  public tileLayers: { [key: string]: any } = {
    "Aerial": L.tileLayer('https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
      attribution: 'Aerial',
      maxNativeZoom: 19,
      maxZoom: 21,
    }),
    "Street": L.tileLayer('https://services.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}', {
      attribution: 'Street',
      maxNativeZoom: 19,
      maxZoom: 21,
    }),
    "Terrain": L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}', {
      attribution: 'Terrain',
      maxNativeZoom: 19,
      maxZoom: 21,
    }),
  };

  public marker: L.Layer;
  public currentUser: UserDto;
  user: Subscription;

  constructor(private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
  ) { }

  readonly portOfOaklandLocation = [37.804363, -122.271111];
  readonly blueIcon = L.icon({
    iconUrl: '/assets/main/map-icons/marker-icon-blue.png',
    shadowUrl: '/assets/main/map-icons/marker-shadow.png',
    iconSize: [22, 35], iconAnchor: [12, 34], shadowAnchor: [9, 26], popupAnchor: [1, -34], tooltipAnchor: [16, -28], shadowSize: [28, 28]
  });
  readonly orangeIcon = L.icon({
    iconUrl: '/assets/main/map-icons/marker-icon-orange.png',
    shadowUrl: '/assets/main/map-icons/marker-shadow.png',
    iconSize: [22, 35], iconAnchor: [12, 34], shadowAnchor: [9, 26], popupAnchor: [1, -34], tooltipAnchor: [16, -28], shadowSize: [28, 28]
  });

  ngOnInit(): void {
    this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
      this.currentUser = result;
      this.cdr.markForCheck();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['editMode']) {
      if (!changes['editMode'].currentValue) {
        this.marker?.dragging.disable();
      } else {
        this.marker?.dragging.enable();
      }
    }
  }

  ngAfterViewInit(): void {
    this.setUpMap();

    // display marker for location if one already exists
    this.displayExistingMapFeatures();

    this.cdr.markForCheck();
  }

  private setUpMap() {
    // add gesture handling to restrict zoom controls
    L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

    const mapOptions: L.MapOptions = {
      minZoom: 6,
      maxZoom: 21,
      zoom: 12,
      center: this.portOfOaklandLocation,
      layers: [
        this.tileLayers["Aerial"],
      ],
      gestureHandling: true,
      fullscreenControl: true
    } as L.MapOptions;
    this.map = L.map(this.mapID, mapOptions);

    // add Port of Oakland jurisdiction extent to map
    const extentGeoJson = PORT_OF_OAKLAND_EXTENT;
    var extentStyle = {
      "color": "#9370DB", // medium purple
      "weight": 1,
      "opacity": 0.75,
      "fillOpacity": 0.25
    };

    L.geoJSON(extentGeoJson, {
      style: extentStyle
    }).addTo(this.map);

    // add layer control to map
    this.layerControl = L.control.layers(this.tileLayers).addTo(this.map);

    // register click event
    this.map.on("click", (event: L.LeafletEvent) => {
      if (!!this.editMode) {
        this.placeMarker(event.latlng);
      }
    });
  }

  protected placeMarker(latlng: L.latLng) {
    if (this.marker) {
      this.map.removeLayer(this.marker);
    }

    this.marker = new L.marker(
      latlng,
      {
        icon: this.blueIcon,
        zIndexOffset: 1000,
        draggable: true
      }).on('dragend', (e, m) => {
        if (!!this.editMode) {
          const latLng = e.target.getLatLng();
          this.location.Latitude = latLng.lat;
          this.location.Longitude = latLng.lng;
          this.cdr.markForCheck();
        }
      });

    this.marker.addTo(this.map);
    this.marker.Latitude = latlng.lat;
    this.marker.Longitude = latlng.lng;

    this.location.Latitude = latlng.lat;
    this.location.Longitude = latlng.lng;

    this.map.setView(this.marker.getLatLng(), 15);
    this.cdr.markForCheck();
  }

  protected displayExistingMapFeatures() {
    if (!!this.location?.Latitude && !!this.location?.Longitude) {
        const existingLocation = new L.latLng(this.location.Latitude, this.location.Longitude);
        this.placeMarker(existingLocation);
        this.marker.dragging.disable();
      }
  }

  resetMap() {
    this.map.removeLayer(this.marker);
    this.marker = undefined;
    this.map.flyTo(this.portOfOaklandLocation, 12);
  }

  cancelEditMode() {
    if (!!this.marker) {
      this.marker.dragging.disable();
    }
    this.refreshLocation();
    this.cancelEditModeChange.emit(true);
  }

  abstract refreshLocation();
  abstract saveForm();

  isSaveDisabled() {
    return !this.location?.Latitude || !this.location?.Longitude;
  }

  ngOnDestroy(): void {
    this.user?.unsubscribe();
  }

  zoomToMe(): void {
    setTimeout(() => {
      this.map.setZoom(17);
    }, 100);
    this.map.locate({setView: true});
  }
}
