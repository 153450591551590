import { ChangeDetectorRef, Directive, OnDestroy, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { YearService } from 'src/app/shared/generated/api/year.service';
import { YearDto } from 'src/app/shared/generated/model/year-dto';

@Directive()
export abstract class ReportBaseGenerateComponent implements OnInit, OnDestroy {
  public years: YearDto[];
  annualReportYear;
  annualReportYearID;
  getReportDataRequest: Subscription;
  public rowData = [];
  public columnDefs: ColDef[];
  public defaultColDef: any;

  constructor(
    private yearService: YearService,
    protected cdr: ChangeDetectorRef
  ) { 
    this.columnDefs = [];
    this.defaultColDef = {
        sortable: true,
        filter: true,
        resizable: true,
        floatingFilter: true,
        suppressMenu: true,
    };
  }

  ngOnInit(): void {
    this.yearService.yearsGet().subscribe((result) => {
        this.years = result.sort((a: YearDto, b: YearDto) => {
          if (a.YearID < b.YearID) return 1;
          if (a.YearID > b.YearID) return -1;
          return 0;
      });
        this.cdr.markForCheck();
        this.annualReportYearID = this.getDefaultAnnualReportYearID();
    });
  }

  getDefaultAnnualReportYearID() {
    const defaultAnnualYear = new Date().getFullYear() - 1;
    return this.years.find(y => y.YearDisplayName == `${defaultAnnualYear}`)?.YearID;
  }

  generate(YearID) {
    this.annualReportYearID = YearID;
    this.columnDefs.length = 0;
    this.cdr.markForCheck();
    const selectedYearIdx = this.years.findIndex(y => y.YearID == YearID);
    if (selectedYearIdx == -1) {
      this.annualReportYear = null;
      this.rowData = [];
    } else {
      this.annualReportYear = this.years[selectedYearIdx].YearDisplayName;
      this.setColumnDefinitions();
      this.refreshGrid();
    }
  }

  abstract setColumnDefinitions();
  abstract refreshGrid();

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  ngOnDestroy(): void {
    this.getReportDataRequest?.unsubscribe();
  }
}
