<form class="form grid-12" #locationForm="ngForm" style="gap: 1rem 1rem;">
    <div class="g-col-12"></div>  <!-- This is needed for proper spacing -->
    <div class="g-col-2">
        <esa-material-button
            type="primary"
            label="Zoom to my Location"
            (click)="zoomToMe()">
        </esa-material-button>
    </div>
    <div *ngIf="editMode" class="g-col-10">
        <p><strong>Directions:</strong> To add a location, click on the map to place a marker. To update the marker's placement, either drag the marker to a new point or click on the map to replace the existing marker. Click save to submit the location, or cancel to discard any changes.</p>
    </div>
    <div class="g-col-12">
        <div [id]="mapID"  [style.height]="'500px'">
        </div>
    </div>

    <ng-container *ngIf="showLatLngDetails">
        <div class="g-col-6">
            <div>
                <strong>Latitude:</strong> {{location?.Latitude | number:'0.5-5'}}
            </div>
        </div>

        <div class="g-col-6">
            <div>
                <strong>Longitude:</strong> {{location?.Longitude | number:'0.5-5'}}
            </div>
        </div>
    </ng-container>

    <div class="g-col-12 actions-bar" *ngIf="editMode" style="margin-top: 1rem">
        <esa-material-button
            style="margin-right: 1rem"
            type="primary"
            label="Save"
            icon="save"
            (click)="saveForm()"
            [disabled]="isSaveDisabled()">
        </esa-material-button>

        <esa-material-button
            type="secondary"
            label="Cancel"
            icon="cancel"
            (click)="cancelEditMode()">
        </esa-material-button>
    </div>
</form>

