<footer class="site-footer grid-12">
    <div class="sitemap">
        <div class="col">
            <h4 class="footer-header">My Account</h4>
            <ul>
                <ng-container *ngIf="authenticationService.isAuthenticated() else unauthenticated">
                    <li><button class="btn-link btn" (click)="authenticationService.logout()">Sign Out</button></li>
                </ng-container>
                <ng-template #unauthenticated>
                    <li><button class="btn-link btn" (click)="authenticationService.login()">Sign In</button></li>
                    <li><button class="btn-link btn" (click)="authenticationService.login()">Create Account</button></li>
                </ng-template>
            </ul>
        </div>

        <div class="col">
            <!-- MCS: I commented this instead of deleting it because we may want to add "Learn More" back to the footer in the future -->
            <!-- <h4 class="footer-header">Learn More</h4>
            <ul>
                <li><a href="/disclaimer/false">Disclaimer</a></li>
            </ul> -->
        </div>
    </div>

    <div class="credits"> 
        <span class="copyright">© {{currentYear}} ESA, All Rights Reserved.</span>

        <a href="https://esassoc.com/" class="esa-logo" alt="ESA" target="_blank">
            <img src="assets/main/logos/esa-logo.svg">
        </a>
        <!-- Example Version Display -->
        <span class="version" [title]="systemInfo?.FullInformationalVersion">Version: {{systemInfo?.Version}} - {{systemInfo?.Environment}}</span>
        <span class="version">Built on: {{systemInfo?.CompilationDateTime | date: "short"}}</span>
    </div>
</footer> 