import {
    Component,
    OnInit,
    HostListener,
    ChangeDetectorRef,
    OnDestroy,
    ViewChildren,
    QueryList,
    ViewChild,
    ElementRef,
    Renderer2,
} from "@angular/core";
import { AuthenticationService } from "src/app/services/authentication.service";
import { environment } from "src/environments/environment";
import { CustomPageDto } from "../../generated/model/custom-page-dto";
import { CustomPageService } from "../../generated/api/custom-page.service";
import { UserDto } from "../../generated/model/user-dto";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { Subscription } from "rxjs";
import { RoleEnum } from "../../generated/enum/role-enum";
import { DropdownToggleDirective } from "../../directives/dropdown-toggle.directive";

@Component({
    selector: "header-nav",
    templateUrl: "./header-nav.component.html",
    styleUrls: ["./header-nav.component.scss"],
})
export class HeaderNavComponent implements OnInit, OnDestroy {
    private watchUserChangeSubscription: Subscription;
    customPagesSubscription: Subscription;
    currentUser: UserDto;

    public windowWidth: number;
    public viewPages: CustomPageDto[] = [];
    public learnMorePages: CustomPageDto[] = [];

    @ViewChild("mobileMenuToggle", { static: false }) mobileMenuEl: ElementRef;

    isMenuOpen = false;

    clickedEl: HTMLElement;

    @HostListener("window:resize", ["$event"])
    resize() {
        this.windowWidth = window.innerWidth;
    }

    @HostListener("click", ["$event"])
    onClick(e) {
        if (typeof e.target.className?.includes == 'function' && (e.target.className?.includes("active") || e.target.className?.includes("dropdown-item"))) {
            this.clickedEl = e.target;
        } else {
            this.clickedEl = null;
        }
    }

    constructor(
        private authenticationService: AuthenticationService,
        private customPageService: CustomPageService,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.watchUserChangeSubscription =
            this.authenticationService.currentUserSetObservable.subscribe(
                (currentUser) => {
                    this.currentUser = currentUser;

                    this.customPagesSubscription = this.customPageService
                        .customPagesWithRolesGet()
                        .subscribe((customPagesWithRoles) => {
                            this.viewPages = customPagesWithRoles.filter(
                                (x) => x.MenuItem.MenuItemName == "View"
                            );
                            this.learnMorePages = customPagesWithRoles.filter(
                                (x) => x.MenuItem.MenuItemName == "LearnMore"
                            );
                        });
                }
            );
    }

    ngOnDestroy() {
        this.cdr.detach();
        this.watchUserChangeSubscription?.unsubscribe();
        this.customPagesSubscription?.unsubscribe();
    }


    public isAuthenticated(): boolean {
        return this.authenticationService.isAuthenticated();
    }

    public getUserName() {
        return this.currentUser ? this.currentUser.FullName : null;
    }

    public login(): void {
        this.authenticationService.login();
    }

    public logout(): void {
        this.authenticationService.logout();

        setTimeout(() => {
            this.cdr.detectChanges();
        });
    }

    public showTestingWarning(): boolean {
        return environment.staging || environment.dev;
    }

    public testingWarningText(): string {
        return environment.staging
            ? "QA Site: Testing Environment!"
            : "Development Site: Testing Environment!";
    }

    public editProfile(): void {
        this.authenticationService.editProfile();
    }

    public isCurrentUserBeingImpersonated(): boolean {
        return this.authenticationService.isCurrentUserBeingImpersonated(
            this.currentUser
        );
    }

    public hasViewMenu(): boolean {
        return this.viewPages.length > 0;
    }

    public hasLearnMenu(): boolean {
        return this.learnMorePages.length > 0;
    }

    public hasManageMenu(): boolean {
        return this.authenticationService.doesCurrentUserHaveOneOfTheseRoles([
            RoleEnum.Admin,
        ]);
    }

    public canViewUsers(): boolean {
        return this.authenticationService.hasPermission(
            this.currentUser,
            PermissionEnum.UserRights,
            RightsEnum.Read
        );
    }

    public canViewFieldDefinitions(): boolean {
        return this.authenticationService.hasPermission(
            this.currentUser,
            PermissionEnum.FieldDefinitionRights,
            RightsEnum.Read
        );
    }

    public canViewCustomPages(): boolean {
        return this.authenticationService.hasPermission(
            this.currentUser,
            PermissionEnum.CustomPageRights,
            RightsEnum.Read
        );
    }

    public canViewProject(): boolean {
        return this.authenticationService.hasPermission(
            this.currentUser,
            PermissionEnum.ProjectRights,
            RightsEnum.Read
        );
    }

    public canViewEquipment(): boolean {
        return this.authenticationService.hasPermission(
            this.currentUser,
            PermissionEnum.EquipmentRights,
            RightsEnum.Read
        );
    }

    public canViewCanister(): boolean {
        return this.authenticationService.hasPermission(
            this.currentUser,
            PermissionEnum.CanisterRights,
            RightsEnum.Read
        );
    }

    public canViewReports(): boolean {
        return this.canViewEquipment() || this.canViewCanister();
    }

    public canViewMapExplorer(): boolean {
        return this.canViewCanister() && this.canViewEquipment();
    }
}
