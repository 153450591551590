<div class="detail-view">
    <div class="table-responsive" style="height: 500px">
        <ag-grid-angular
            #equipmentFileGrid
            style="width: 100%; height: 100%"
            class="ag-theme-balham"
            [rowData]="rowData"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [suppressMenuHide]="true"
            (gridReady)="onEquipmentFileGridReady($event)"
            [frameworkComponents]="frameworkComponents">
        </ag-grid-angular>
    </div>
    
    <p>
        <small>
            Supported file types: .png, .jpg, .pdf, .doc, .docx, .xlsx, .csv 
        </small>
    </p>

    <pog-file-uploader 
        [fileRoute]="fileRoute"
        (hasFilesToUpload)="hasFilesToUpload($event)"
        (fileUploadStarted)="fileUploadStarted($event)" 
        (fileUploadSuccess)="fileUploadSuccess($event)"
        (fileUploadFailure)="fileUploadFailure($event)"
        *ngIf="fileRoute && canUploadEquipmentFile(currentUser)">
    </pog-file-uploader>
</div>
