<header class="site-header">
    <div *ngIf="showTestingWarning()" class="qa-warning">
        <span class="fa fa-warning"></span>
        {{ testingWarningText() }}
    </div>

     <nav class="site-nav">
        <a routerLink="/" routerLinkActive="active" class="site-logo">
            <img src="assets/main/logos/pog_logo.png" alt="Port of Oakland" />
        </a>

        <button class="hamburger-btn" [dropdownToggle]="mobileMenuToggle" [clickedEl]="clickedEl" type="button" aria-controls="hamburger-btn" aria-expanded="false">
             <span class="sr-only">Open main menu</span>
            <svg aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path>
            </svg>
        </button>

        <div class="site-nav__wrapper" #mobileMenuToggle>
            <a routerLink="/" routerLinkActive="active" class="site-logo-mobile">
                <img src="assets/main/logos/pog_logo.png" alt="Pog" />
            </a>

            <ul class="site-nav__links site-nav__main">
                <li class="nav-item" *ngIf="canViewEquipment()">
                    <a routerLink="/equipment" class="nav-item nav-link" role="button" routerLinkActive="active">
                        Equipment
                    </a>
                </li>

                <li class="nav-item" *ngIf="canViewCanister()">
                    <a routerLink="/canisters" class="nav-link" role="button" routerLinkActive="active">
                        Canisters
                    </a>
                </li>

                <li class="nav-item dropdown" routerLinkActive="active" *ngIf="canViewReports()">
                    <a [dropdownToggle]="reportsToggle" [clickedEl]="clickedEl" class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span>Reports</span>
                    </a>
                    <div #reportsToggle class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a *ngIf="canViewEquipment()" routerLink="/reports/equipment" class="dropdown-item">
                            Equipment
                        </a>
                        <a *ngIf="canViewCanister()" routerLink="/reports/canisters" class="dropdown-item">
                            Canisters
                        </a>
                    </div>
                </li>

                <li *ngIf="canViewMapExplorer()" class="nav-item">
                    <a routerLink="/map-explorer" class="nav-link" role="button" routerLinkActive="active">
                        Map Explorer
                    </a>
                </li>

                 <li class="nav-item dropdown" routerLinkActive="active" *ngIf="hasViewMenu()">
                    <a [dropdownToggle]="viewToggle" [clickedEl]="clickedEl" class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span>View</span>
                    </a>

                    <div #viewToggle class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a *ngFor="let viewPage of viewPages" routerLink="/custom-pages/{{ viewPage.CustomPageVanityUrl }}" class="dropdown-item">
                            {{ viewPage.CustomPageDisplayName }}
                        </a>
                    </div>
                </li>

                <li class="nav-item dropdown" routerLinkActive="active" *ngIf="hasLearnMenu()">
                    <a [dropdownToggle]="learnMoreToggle" [clickedEl]="clickedEl" class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span>Learn More</span>
                    </a>

                    <div #learnMoreToggle class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a *ngFor="let learnMorePage of learnMorePages" routerLink="/custom-pages/{{ learnMorePage.CustomPageVanityUrl }}" class="dropdown-item">
                            {{ learnMorePage.CustomPageDisplayName }}
                        </a>
                    </div>
                </li>

                <li class="nav-item" *ngIf="hasManageMenu()">
                    <a routerLink="/manage" class="nav-link" role="button" routerLinkActive="active">
                        Manage
                    </a>
                </li>
            </ul>

            <ul class="site-nav__links site-nav__utilities" #testRef>
                <li class="nav-item" routerLinkActive="active" *ngIf="!isAuthenticated()">
                    <a (click)="login()" routerLinkActive="active" class="nav-link">Sign In</a>
                </li>

                <li class="nav-item dropdown welcomeUser" routerLinkActive="active" *ngIf="isAuthenticated()">
                    <a [dropdownToggle]="helpToggle" [clickedEl]="clickedEl" class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span class="username">{{isCurrentUserBeingImpersonated() ? 'Impersonating' : 'Welcome'}} {{getUserName()}}</span>
                    </a>

                    <div #helpToggle class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                        <a routerLink="/help" class="dropdown-item">
                            Get Help
                        </a>
                        <a (click)="editProfile()" class="dropdown-item">Edit Profile</a>
                        <a (click)="logout()" class="dropdown-item">{{isCurrentUserBeingImpersonated() ? 'Stop Impersonating' : 'Sign Out'}}</a>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</header>
