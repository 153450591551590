<div class="list-view">
    <div class="page-header">
        <div class="actions-bar flex-between">
            <h1 class="page-title">Canisters</h1>
            <esa-material-button *ngIf="canCreateCanister" label="Canister" icon="add" type="primary" (click)="navigateToCanisterCreatePage()">
            </esa-material-button>
        </div>
    </div>

    
    <div class="filter-btn-group align-right mb-3">
        <pog-clear-grid-filters-button class="mr-2" [grid]="canistersGrid"></pog-clear-grid-filters-button>
        <pog-csv-download-button [grid]="canistersGrid" fileName="canisters" colIDsToExclude=""></pog-csv-download-button>
    </div>

    <div>
        <div class="table-responsive">
            <ag-grid-angular
                #CanistersGrid
                style="width: 100%; height: 100%"
                class="ag-theme-balham"
                [rowData]="rowData"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                [suppressMenuHide]="true"
                (gridReady)="onCanistersGridReady($event)"
                (firstDataRendered)="onFirstDataRendered($event)">
            </ag-grid-angular>
        </div>
    </div>
</div>
