<div style="margin-top: 2rem;">
    <esa-material-button type="primary" label="Select Files" icon="file_open" (click)="fileInput.click()" [disabled]="isLoading || (!multiple && filesToUpload.length > 0)"></esa-material-button>

    <input type="file" [multiple]="multiple" hidden (change)="onFilesSelected(fileInput.files)" #fileInput [accept]="accept"/>

    <div *ngIf="filesToUpload && filesToUpload.length > 0" style="margin-top: 2rem; display:inline-block">
        <ul style="margin-bottom: 4rem;">
            <li *ngFor="let file of filesToUpload; let i = index;" style="font-size: 16px; height: 32px;">
                <img *ngIf="isPhoto(file) && showPreviewForPhotos" [src]="photoUrl[i]" width="80" height="80" style="display:inline; max-width: inherit;"/>
                <span>{{ file.name }}</span>
                <esa-material-button *ngIf="!isLoading" type="icon" icon="disabled_by_default" (click)="spliceFiles(i)" style="margin-left: 1rem;"></esa-material-button>
            </li> 
        </ul>

        <mat-spinner *ngIf="isLoading" style="margin: 1rem;"></mat-spinner>

        <esa-material-button style="margin-left: 1rem" type="primary" label="Upload Files" icon="publish" (click)="uploadFiles()" [disabled]="isLoading" *ngIf="immediate"></esa-material-button>
    </div>
</div>