import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { ColDef } from "ag-grid-community";

@Injectable({
    providedIn: "root",
})
export class DateColumnCreatorService {
    constructor() {}

    public createDateColumnDef(headerName: string, fieldName: string, dateFormat: string, minWidth = null): ColDef {
        let datePipe = new DatePipe("en-US");

        let colDef = {
            headerName: headerName,
            valueGetter: function (params: any) {
                return datePipe.transform(params.data[fieldName], dateFormat);
            },
            filter: "agDateColumnFilter",
            filterParams: {
                filterOptions: ["inRange"],
                comparator: this.dateFilterComparator,
            },
            flex: 1,
            comparator: function (dateA, dateB, nodeA, nodeB, isDescending) {
                let valueA = Date.parse(dateA);
                let valueB = Date.parse(dateB);

                return valueA - valueB;
            },
        };

        if (!!minWidth) {
            colDef['minWidth'] = minWidth;
        } else {
            colDef['flex'] = 1;
        }
        return colDef;
    }

    private dateFilterComparator(filterLocalDateAtMidnight, cellValue) {
        const cellDate = Date.parse(cellValue);
        if (cellDate == filterLocalDateAtMidnight) {
            return 0;
        }
        
        return cellDate < filterLocalDateAtMidnight ? -1 : 1;
    }
}