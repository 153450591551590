import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CurrentCanisterService } from 'src/app/services/current-canister.service';
import { EditViewEventService } from 'src/app/services/edit-view-event.service';
import { CanisterDto } from 'src/app/shared/generated/model/canister-dto';
import { CanisterUpsertDto } from 'src/app/shared/generated/model/canister-upsert-dto';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { CanisterFormComponent } from '../../shared/canister-form/canister-form.component';

@Component({
  selector: 'pog-canister-detail-summary',
  templateUrl: './canister-detail-summary.component.html',
  styleUrls: ['./canister-detail-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CanisterDetailSummaryComponent implements OnInit, OnDestroy {
  @ViewChild("updateCanisterForm", { read: CanisterFormComponent }) form: CanisterFormComponent;

  public canister$: Observable<any>;
  
  public canister: CanisterDto;
  public currentUser: UserDto;
  public editMode = false;
  public canisterUpsertDto: CanisterUpsertDto;

  user: Subscription;

  constructor(
      private currentCanisterService: CurrentCanisterService,
      private authenticationService: AuthenticationService,
      private editViewEventService: EditViewEventService,
      private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
      this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
          this.currentUser = result;
          this.cdr.markForCheck();
      });

      this.canister$ = this.currentCanisterService.getCurrentCanister().pipe(
          map((data) => {
              this.canister = data;
              this.canisterUpsertDto = this.currentCanisterService.createCanisterDto(this.canister);
              this.cdr.markForCheck();
              return data;
          })
      );
  }

  ngOnDestroy(): void {
      this.user.unsubscribe();
  }

  refreshCanister(result: any) {
      this.editMode = false;
      this.currentCanisterService.setCurrentCanister(result);
      this.editViewEventService.editButtonClicked.next(this.editMode);
      this.cdr.detectChanges();
  }

  save(result) {
      this.refreshCanister(result);
  }

  canEdit(): boolean {
      return this.currentCanisterService.canEditCurrentCanister(this?.currentUser, this.canister) && !this.editMode;
  }

  enableEditMode() {
      this.editMode = true;
      this.editViewEventService.editButtonClicked.next(this.editMode);
      this.cdr.markForCheck();
  }

  cancelEdit() {
      this.editMode = false;
      this.editViewEventService.editButtonClicked.next(this.editMode);
      this.cdr.markForCheck();
  }

  canExit() {
      if (this.editMode) {
          return JSON.stringify(this.canisterUpsertDto) === JSON.stringify(this.form.canisterUpsertDto);
      } else {
          return true;
      }
  }
}
