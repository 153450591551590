import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CurrentCanisterService } from 'src/app/services/current-canister.service';
import { EditViewEventService } from 'src/app/services/edit-view-event.service';
import { ConfirmDialog } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { CanisterService } from 'src/app/shared/generated/api/canister.service';
import { PermissionEnum } from 'src/app/shared/generated/enum/permission-enum';
import { CanisterDto } from 'src/app/shared/generated/model/canister-dto';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { RightsEnum } from 'src/app/shared/models/enums/rights.enum';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'pog-canister-detail',
  templateUrl: './canister-detail.component.html',
  styleUrls: ['./canister-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CanisterDetailComponent implements OnInit {
  public canister$: Observable<any>;
  public canister?: CanisterDto | undefined;

  public currentUser: UserDto;
  public editMode: boolean = false;
  public canisterSideNav: any[] = [];

  editViewButtonClicked: Subscription;
  user: Subscription;
  deleteSubscription: Subscription;

  constructor(
    private canisterService: CanisterService,
    private currentCanisterService: CurrentCanisterService,
    private authenticationService: AuthenticationService,
    private editViewEventService: EditViewEventService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    const canisterID = parseInt(this.route.snapshot.paramMap.get("id"));
    this.canisterSideNav = [
      {
        Name: "Summary",
        Link: `/canisters/${canisterID}`
      },
      {
        Name: "Location",
        Link: `/canisters/${canisterID}/location`
      },
      {
        Name: "Annual Weight",
        Link: `/canisters/${canisterID}/year-end-weight-list`
      },
      {
        Name: "File List",
        Link: `/canisters/${canisterID}/file-list`
      },
    ];

    this.canisterService.canistersCanisterIDGet(canisterID).subscribe((data) => {
        this.currentCanisterService.setCurrentCanister(data);
      },
        () => {
          this.router.navigate([""]);
        });

    this.canister$ = this.currentCanisterService.getCurrentCanister().pipe(
      map((data) => {
        this.canister = data;
        this.cdr.markForCheck();
        return data;
      })
    );

    this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
      this.currentUser = result;
      this.cdr.markForCheck();
    });

    this.editViewButtonClicked = this.editViewEventService.listenForEditButtonClicked().subscribe((result) => {
      this.editMode = result;
      this.cdr.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.currentCanisterService.setCurrentCanister(null);
    this.user.unsubscribe();
    this.editViewButtonClicked?.unsubscribe();
    this.deleteSubscription?.unsubscribe();
  }

  refreshCanister(result: any) {
    this.canister = result;
    this.currentCanisterService.setCurrentCanister(this.canister);
    this.cdr.detectChanges();
  }

  canDelete() {
    return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.CanisterRights, RightsEnum.Delete);
  }

  public delete() {
    const dialogRef = this.dialog.open(ConfirmDialog, {
        data: {
            header: "Delete Canister",
            text: `You are about to delete canister ${this.canister.GasContainerOrGasCartID}. This action cannot be undone. Are you sure you wish to proceed?`,
        }
    });

    return dialogRef.afterClosed().subscribe((confirmed) => {
        if (confirmed) {
            this.canisterService.canistersCanisterIDDelete(this.canister.CanisterID).subscribe(() => {
              this.alertService.pushAlert(new Alert(`Canister was successfully deleted.`, AlertContext.Success, true));
              this.router.navigate(["/canisters"]);
            }, error => {
              this.alertService.pushAlert(new Alert(`There was an error deleting the canister. Please try again.`, AlertContext.Danger, true));
            });
          }
    });
}

  hasReadOnly() {
    const read = this.authenticationService.hasPermission(this?.currentUser, PermissionEnum.CanisterRights, RightsEnum.Read);
    const update = this.authenticationService.hasPermission(this?.currentUser, PermissionEnum.CanisterRights, RightsEnum.Update);
    const remove = this.authenticationService.hasPermission(this?.currentUser, PermissionEnum.CanisterRights, RightsEnum.Delete);
    const create = this.authenticationService.hasPermission(this?.currentUser, PermissionEnum.CanisterRights, RightsEnum.Create);
    if (read && !update && !remove && !create) {
      return true;
    } else {
      return false;
    }
  }
}
