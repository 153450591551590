import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialog } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { FileUploaderComponent } from 'src/app/shared/components/file-uploader/file-uploader.component';
import { YearEndWeightFileService } from 'src/app/shared/generated/api/year-end-weight-file.service';
import { YearEndWeightService } from 'src/app/shared/generated/api/year-end-weight.service';
import { ReasonForMeasurementDto, YearDto, YearEndWeightDto, YearEndWeightFileDto, YearEndWeightUpsertDto } from 'src/app/shared/generated/model/models';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { AlertService } from 'src/app/shared/services/alert.service';
import { FileService } from 'src/app/shared/services/file/file.service';

@Component({
  selector: 'pog-year-end-weight-form',
  templateUrl: './year-end-weight-form.component.html',
  styleUrls: ['./year-end-weight-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YearEndWeightFormComponent implements OnInit {
  @ViewChild("yearEndWeightForm", { read: NgForm }) form: NgForm;
  @ViewChild("uploader") uploader: FileUploaderComponent;
  
  @Output() formSubmitted = new EventEmitter<any>();
  @Output() cancelEditModeChange = new EventEmitter<boolean>();

  @Input() canisterID: number;
  @Input() tareWeight: number;
  @Input() yearEndWeightID: number;
  @Input() yearEndWeight: YearEndWeightDto;
  @Input() editMode: boolean;
  @Input() years: YearDto[];
  @Input() reasonsForMeasurement: ReasonForMeasurementDto[];

  yearEndWeightUpsertDto: YearEndWeightUpsertDto;
  canisterWeight: any = {};
  fileRoute: string;
  file: YearEndWeightFileDto;
  fileUrl: any;

  constructor(
    private yearEndWeightService: YearEndWeightService,
    private yearEndFileService: YearEndWeightFileService,
    private alertService: AlertService,
    private cdr: ChangeDetectorRef,
    private fileService: FileService,
    public dialog: MatDialog) {}

  ngOnInit(): void {
    if (this.yearEndWeight) {
        this.setForm();
    }
    if (!!this.yearEndWeightID) {
      this.fileRoute = `canisters/${this.canisterID}/year-end-weights/${this.yearEndWeightID}/files`
      this.refreshData();
    }
  }

  refreshData() {
    this.yearEndFileService.canistersCanisterIDYearEndWeightsYearEndWeightIDFilesGet(this.canisterID, this.yearEndWeightID)
    .subscribe((result) => {
      this.file = result[0];
      var self = this;
      if (!!this.file) {
        this.fileService.downloadFile(`${this.fileRoute}/${this.file.BlobName}`).subscribe((result) => {
          if (!result) {
              return;
          }
  
          const file = new File([result], this.file.Name);
      
          var reader = new FileReader();
          reader.onload = function(){
            self.fileUrl = reader.result;
            self.cdr.detectChanges();
          };
          reader.readAsDataURL(file);
        });
      }
      
      this.cdr.detectChanges();
    });
  }

    
  downloadFile() {
    this.fileService.downloadFile(`${this.fileRoute}/${this.file.BlobName}`).subscribe((result) => {
        if (!result) {
            return;
        }

        const file = new File([result], this.file.Name);
        if (navigator.msSaveBlob) {
            return navigator.msSaveBlob(file, file.name);
        }

        this.downloadAsHref(URL.createObjectURL(file), file.name);
    });
  }

  downloadAsHref(href: string, filename: string) {
      const tempDownloadLink = document.createElement("a");
      tempDownloadLink.href = href;
      tempDownloadLink.download = filename;

      document.body.appendChild(tempDownloadLink);
      tempDownloadLink.click();
      document.body.removeChild(tempDownloadLink);
  }

  setForm() {
    this.yearEndWeightUpsertDto = new YearEndWeightUpsertDto({
      ReportingYearID: this.yearEndWeight.ReportingYear?.YearID,
      WeightDate: this.yearEndWeight.WeightDate,
      ReasonForMeasurementID: this.yearEndWeight.ReasonForMeasurement?.ReasonForMeasurementID,
      Weight: this.yearEndWeight.Weight,
      Notes: this.yearEndWeight.Notes
    });

  }

  saveForm(form: NgForm) {
    if (!!this.yearEndWeightID) {
        this.yearEndWeightService.canistersCanisterIDYearEndWeightsYearEndWeightIDPut(this.canisterID, this.yearEndWeightID, this.yearEndWeightUpsertDto)
            .subscribe((result) => {
              this.yearEndWeight = result;
              if (!!this.uploader && this.uploader.filesToUpload.length > 0) {
                this.uploader.uploadFiles();
              } else {
                this.alertService.pushAlert(new Alert("The canister was successfully updated.", AlertContext.Success), 5000);
                this.formSubmitted.emit(this.yearEndWeight);
              }
        });
    } else {
      this.yearEndWeightService.canistersCanisterIDYearEndWeightsPost(this.canisterID, this.yearEndWeightUpsertDto)
        .subscribe((result) => { this.yearEndWeight = result;
          if (this.uploader.filesToUpload.length > 0) {
            this.fileRoute = `canisters/${this.canisterID}/year-end-weights/${result.YearEndWeightID}/files`
            this.cdr.detectChanges();
            this.uploader.uploadFiles();
          } else {
            this.formSubmitted.emit(result);
            this.alertService.pushAlert(new Alert("The canister was successfully created.", AlertContext.Success), 5000);
          }
        },
        () => {
            this.formSubmitted.emit(null);
        });
    }
  }

  uploadSuccess(event) {
    this.alertService.pushAlert(new Alert("The canister was successfully updated.", AlertContext.Success), 5000);
    this.formSubmitted.emit(this.yearEndWeight);
  }

  cancelEditMode() {
      this.setForm();
      this.cancelEditModeChange.emit(true);
  }

  deleteFile() {
    const dialogRef = this.dialog.open(ConfirmDialog, {
        data: {
            header: "Delete Project File",
            text: `You are about to delete the file ${this.file.Name}. This action cannot be undone. Are you sure you wish to proceed?`,
        }
    });

    return dialogRef.afterClosed().subscribe((confirmed) => {
        if (confirmed) {
            this.yearEndFileService.canistersCanisterIDYearEndWeightsYearEndWeightIDFilesBlobNameDelete(this.canisterID, this.yearEndWeightID, this.file.BlobName).subscribe(() => {
              this.alertService.pushAlert(new Alert(`File was successfully deleted.`, AlertContext.Success, true));
              this.refreshData();
            }, error => {
              this.alertService.pushAlert(new Alert(`There was an error deleting the file. Please try again.`, AlertContext.Danger, true));
            });
        }
    });
  }

  onBlur(event): void {
    const pounds = parseFloat(this.canisterWeight.Pounds);
    const ozs = parseFloat(this.canisterWeight.Ounces);
    if (!isNaN(pounds) || !isNaN(ozs)) {
      let canisterWeight = 0.0;
      if (!isNaN(pounds)) {
        canisterWeight = pounds;
      }
      if (!isNaN(ozs)) {
        canisterWeight += (ozs / 16);
      }
      this.canisterWeight.AmountOfGas = Math.round((canisterWeight - this.tareWeight) * 100) / 100;
      this.cdr.markForCheck();
      return;
    }
    this.canisterWeight.AmountOfGas = "";
    this.cdr.markForCheck();
  }
}