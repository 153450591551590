import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CurrentProjectService } from 'src/app/services/current-project.service';
import { TinymceEditorComponent } from 'src/app/shared/components/tinymce-editor/tinymce-editor.component';
import { ProjectService } from 'src/app/shared/generated/api/project.service';
import { ProjectUpsertDto } from 'src/app/shared/generated/model/project-upsert-dto';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'pog-project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.scss'],
})
export class ProjectFormComponent implements OnInit {
  @ViewChild("projectForm", { read: NgForm }) form: NgForm;
  @ViewChild("tinyMceEditor") tinyMceEditor: TinymceEditorComponent;

  @Output() formSubmitted = new EventEmitter<any>();
  @Output() cancelEditModeChange = new EventEmitter<boolean>();

  @Input() projectID: number;
  @Input() project: ProjectUpsertDto;
  @Input() editMode: boolean;

  projectUpsertDto: ProjectUpsertDto;

  constructor(private currentProjectService: CurrentProjectService,
    private projectService: ProjectService,
    private alertService: AlertService) {}

  ngOnInit(): void {
      if (this.project) {
          this.setForm();
      }
  }

  setForm() {
      this.projectUpsertDto = this.currentProjectService.createProjectDto(this.project);
  }

  saveForm(form: NgForm) {
    if (!!this.projectID) {
        this.projectService.projectsProjectIDPut(this.projectID, this.projectUpsertDto)
            .subscribe((result) => {
            this.alertService.pushAlert(new Alert("The project was successfully updated.", AlertContext.Success), 5000);
            this.formSubmitted.emit(result);
        });
    } else {
      this.projectService.projectsPost(this.projectUpsertDto)
        .subscribe((result) => {
          this.formSubmitted.emit(result);
            this.alertService.pushAlert(new Alert("The project was successfully created.", AlertContext.Success), 5000);
        },
        () => {
            this.formSubmitted.emit(null);
        });
    }
  }

  cancelEditMode() {
      this.setForm();
      this.cancelEditModeChange.emit(true);
  }
}
