import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentEquipmentService } from 'src/app/services/current-equipment.service';
import { EquipmentUpsertDto } from 'src/app/shared/generated/model/equipment-upsert-dto';
import { EquipmentFormComponent } from '../shared/equipment-form/equipment-form.component';

@Component({
  selector: 'pog-equipment-create',
  templateUrl: './equipment-create.component.html',
  styleUrls: ['./equipment-create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentCreateComponent implements OnInit, OnDestroy {        
  @ViewChild("createNewEquipmentForm", { read: EquipmentFormComponent }) form: EquipmentFormComponent;

  public equipment: EquipmentUpsertDto = new EquipmentUpsertDto();
  public equipmentUpsertDto: EquipmentUpsertDto;
  private equipmentResponse: any;

  editMode = true;

  constructor(
      private currentEquipmentService: CurrentEquipmentService,
      private router: Router,
      private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
      this.equipmentUpsertDto = this.currentEquipmentService.createEquipmentDto(this.equipment);
  }

  ngOnDestroy() {
      this.cdr.detach();
  }

  save(result): void {
    if (!!result) {
        this.equipmentResponse = result;
        this.router.navigateByUrl(`/equipment/${result.EquipmentID}`);
    } else {
        this.cdr.detectChanges();
    }
  }

  cancelEdit() {
      this.router.navigateByUrl("/equipment");
  }

  canExit() {
      if (this.equipmentResponse) {
          return true;
      } else {
          return JSON.stringify(this.equipmentUpsertDto) === JSON.stringify(this.form.equipmentUpsertDto);
      }
  }
}
