//  IMPORTANT:
//  This file is generated. Your changes will be lost.
//  Source Table: [dbo].[EquipmentType]

export enum EquipmentTypeEnum {
  Busbar = 1,
  Capacitor = 2,
  CircuitBreaker = 3,
  CircuitSwitcher = 4,
  CouplingCapacitorPotentialDevice = 5,
  GasInsulatedSubstation = 6,
  Interrupter = 7,
  Switch = 8,
  Switchgear = 9,
  Transformer = 10,
  Other = 11
}
