<div class="main">
    <div *ngIf="isLoading" class="text-center">
        <span class="fa fa-spinner loading-spinner"></span>
    </div>

    <ng-container *ngIf="customPage$ | async as customPage">
        <div class="page-header">
            <h1 class="page-title">{{ customPage.CustomPageDisplayName }}</h1>
        </div>

        <div class="edit-panel pb-3" *ngIf="!isLoading">
            <ng-container *ngIf="!isEditing">
                <div *ngIf="showEditButton()" class="customPageContent" style="position: relative; min-height: 60px;">
                    <esa-material-button class="hoverEditButton" icon="edit" type="clear" (click)="enterEdit()"></esa-material-button>
                    <div *ngIf="!emptyContent" class="copy copy-2 custom-content-copy" [innerHtml]="customPageContent"></div>
    
                    <div *ngIf="emptyContent" class="hoverEmptySpace"></div>
                </div>
                <div *ngIf="!showEditButton()" [innerHtml]="customPageContent"></div>
            </ng-container>
    
            <div *ngIf="isEditing">
                <editor #tinyMceEditor
                    [init]="tinyMceConfig"
                    [(ngModel)]="editedContent"
                    ></editor>
                <div style="float:right;" >
                    <esa-material-button type="primary" label="Save" (click)="saveEdit()"></esa-material-button>
                    <esa-material-button type="clear" label="Cancel" (click)="cancelEdit()"></esa-material-button>
                </div>
            </div>
        </div>
    </ng-container>
</div>