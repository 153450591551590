import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { filter, take } from "rxjs/operators";
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CurrentCanisterService } from 'src/app/services/current-canister.service';
import { EditViewEventService } from 'src/app/services/edit-view-event.service';
import { CanisterDto } from 'src/app/shared/generated/model/canister-dto';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { YearEndWeightService } from 'src/app/shared/generated/api/year-end-weight.service';
import { YearEndWeightDto } from 'src/app/shared/generated/model/year-end-weight-dto';
import { ReasonForMeasurementService } from 'src/app/shared/generated/api/reason-for-measurement.service';
import { YearService } from 'src/app/shared/generated/api/year.service';
import { YearDto } from 'src/app/shared/generated/model/year-dto';
import { ReasonForMeasurementDto } from 'src/app/shared/generated/model/reason-for-measurement-dto';
import { ReasonForMeasurementEnum } from 'src/app/shared/generated/enum/reason-for-measurement-enum';

@Component({
    selector: 'pog-canister-year-end-weight-list',
    templateUrl: './canister-year-end-weight-list.component.html',
    styleUrls: ['./canister-year-end-weight-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CanisterYearEndWeightListComponent implements OnInit, OnDestroy {
    public canister$: Observable<any>;
    public canister: CanisterDto;
    public currentUser: UserDto;
    public editMode = false;
    public yearEndWeights: YearEndWeightDto[];

    public editingID:number = 0;
    public newYearEndWeight: YearEndWeightDto = null;
    user: Subscription;
    
    public years: YearDto[];
    public reasonsForMeasurement: ReasonForMeasurementDto[];

    constructor(
        private currentCanisterService: CurrentCanisterService,
        private yearEndWeightService: YearEndWeightService,
        private yearService: YearService,
        private reasonsForMeasurementService: ReasonForMeasurementService,
        private authenticationService: AuthenticationService,
        private editViewEventService: EditViewEventService,
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });

        this.yearService.yearsGet().subscribe((result) => {
            this.years = result;
            this.cdr.markForCheck();
        });;

        this.reasonsForMeasurementService.reasonsForMeasurementGet().subscribe((result) => {
            this.reasonsForMeasurement = result;
            this.cdr.markForCheck();
        });;

        this.currentCanisterService.getCurrentCanister().pipe(            
            filter((s) => s != null),
            take(1)
        ).subscribe((data) => {
            this.canister = data;
            this.refreshData();
            this.cdr.markForCheck();   
            return data;
        });
                
    }
    
    refreshData() {
        this.yearEndWeightService.canistersCanisterIDYearEndWeightsGet(this.canister.CanisterID).subscribe((yearEndResults) => {
            this.yearEndWeights = yearEndResults.sort((a, b) => {
                return Number(b.ReportingYear.YearName) - Number(a.ReportingYear.YearName);            
            });
            this.cdr.markForCheck();  
        });
    }

    ngOnDestroy(): void {
        this.user.unsubscribe();
    }
    
    addYearEndWeight() {
        this.editMode = true;
        this.editingID = -1;
        this.newYearEndWeight = new YearEndWeightDto({ReasonForMeasurement: {ReasonForMeasurementID: ReasonForMeasurementEnum.EndOfCalendarYearWeighing}}) 
        
        this.cdr.markForCheck();
    }

    save(result) {
        this.editingID = 0;
        this.editMode = false;
        this.newYearEndWeight = null;
        this.refreshData();
        this.cdr.markForCheck();  
    }

    canEdit(): boolean {
        return this.currentCanisterService.canEditCurrentCanister(this?.currentUser, this.canister) && !this.editMode;
    }

    canAdd(): boolean {
        return this.canEdit() && this.yearEndWeights && this.years && this.yearEndWeights.length < this.years.length;
    }

    enableEditMode(idToEdit:number) {
        this.editingID = idToEdit;
        this.editMode = true;
        this.editViewEventService.editButtonClicked.next(this.editMode);
        this.cdr.markForCheck();
    }

    cancelEdit() {
        this.editingID = 0;
        this.editMode = false;
        this.newYearEndWeight = null;
        this.editViewEventService.editButtonClicked.next(this.editMode);
        this.cdr.markForCheck();
    }

    canExit() {
        if (this.editMode) {
            return false; // better than nothing here
        } else {
            return true;
        }
    }
}
