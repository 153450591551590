import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PermissionEnum } from '../shared/generated/enum/permission-enum';
import { CanisterDto } from '../shared/generated/model/canister-dto';
import { CanisterUpsertDto } from '../shared/generated/model/canister-upsert-dto';
import { UserDto } from '../shared/generated/model/user-dto';
import { RightsEnum } from '../shared/models/enums/rights.enum';
import { AuthenticationService } from './authentication.service';
import { CanisterLocationDto } from '../shared/generated/model/models';

@Injectable({
  providedIn: 'root'
})
export class CurrentCanisterService {
  currentCanister$ = new BehaviorSubject<CanisterDto>(null);

  constructor(private authenticationService: AuthenticationService) {}

  setCurrentCanister(canister: CanisterDto): void {
      this.currentCanister$.next(canister);
  }

  getCurrentCanister(): Observable<any> {
      return this.currentCanister$.asObservable();
  }

  createCanisterDto(canister: CanisterDto) {
      const canisterDto = new CanisterUpsertDto({
          VesselTypeID: canister?.VesselType?.VesselTypeID,
          PropertyOfGieStatusID: canister?.PropertyOfGieStatus?.PropertyOfGieStatusID,
          GasContainerOrGasCartID: canister?.GasContainerOrGasCartID,
          GasContainerNickname: canister?.GasContainerNickname,
          CoveredInsulatingGasID: canister?.CoveredInsulatingGas?.CoveredInsulatingGasID,
          TareWeight: canister?.TareWeight,
          CanisterStatusID: canister?.CanisterStatus?.CanisterStatusID,
          RetirementDate: canister?.RetirementDate ? new Date(canister.RetirementDate) : null,
      });
      return canisterDto;
  }

  canEditCurrentCanister(currentUser: UserDto, canister: CanisterDto): boolean {
      return this.authenticationService.hasPermission(currentUser, PermissionEnum.CanisterRights, RightsEnum.Update);
  }

  createCanisterLocationDto(canister: CanisterDto): CanisterLocationDto {
     return new CanisterLocationDto({
        Latitude: canister?.Latitude,
        Longitude: canister?.Longitude
     });
  }
}
