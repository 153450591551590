<div class="detail-view">
    <div class="actions-bar">
        <esa-material-button *ngIf="canEdit()" type="primary" icon="edit" (click)="enableEditMode()"> </esa-material-button>
    </div>

    <ng-container *ngIf="canister$ | async as c">
        <pog-canister-form
            #updateCanisterForm
            [canisterID]="c.CanisterID"
            [canister]="c"
            [editMode]="editMode"
            (formSubmitted)="save($event)"
            (cancelEditModeChange)="cancelEdit()"></pog-canister-form>
    </ng-container>
</div>
