/**
 * Pog.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export class EquipmentUpsertDto { 
    SerialNumber: string;
    EquipmentTypeID: number;
    EquipmentTypeOther?: string;
    SealTypeID: number;
    ManufacturerID?: number;
    ManufactureYear?: number;
    VoltageCapacity?: number;
    NameplateCapacity?: number;
    CoveredInsulatingGasID?: number;
    ContactName?: string;
    ContactEmail?: string;
    ContactPhone?: string;
    EquipmentStatusID?: number;
    RetirementDate?: string;
    DepartmentID?: number;
    GasTransferredOutAmount?: number;
    Address?: string;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}
