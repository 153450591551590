import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CurrentEquipmentService } from 'src/app/services/current-equipment.service';
import { EditViewEventService } from 'src/app/services/edit-view-event.service';
import { ConfirmDialog } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { EquipmentService } from 'src/app/shared/generated/api/equipment.service';
import { PermissionEnum } from 'src/app/shared/generated/enum/permission-enum';
import { EquipmentDto } from 'src/app/shared/generated/model/equipment-dto';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { RightsEnum } from 'src/app/shared/models/enums/rights.enum';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'pog-equipment-detail',
  templateUrl: './equipment-detail.component.html',
  styleUrls: ['./equipment-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class EquipmentDetailComponent implements OnInit {
  public equipment$: Observable<any>;
  public equipment?: EquipmentDto | undefined;

  public currentUser: UserDto;
  public editMode: boolean = false;
  public equipmentSideNav: any[] = [];

  editViewButtonClicked: Subscription;
  user: Subscription;
  deleteSubscription: Subscription;

  constructor(
    private equipmentService: EquipmentService,
    private currentEquipmentService: CurrentEquipmentService,
    private authenticationService: AuthenticationService,
    private editViewEventService: EditViewEventService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    const equipmentID = parseInt(this.route.snapshot.paramMap.get("id"));
    this.equipmentSideNav = [
      {
        Name: "Summary",
        Link: `/equipment/${equipmentID}`
      },
      {
        Name: "Location",
        Link: `/equipment/${equipmentID}/location`
      },
      {
        Name: "File List",
        Link: `/equipment/${equipmentID}/file-list`
      },
    ];
    
    this.equipmentService.equipmentEquipmentIDGet(equipmentID).subscribe((data) => {
        this.currentEquipmentService.setCurrentEquipment(data);
      },
        () => {
          this.router.navigate([""]);
        });

    this.equipment$ = this.currentEquipmentService.getCurrentEquipment().pipe(
      map((data) => {
        this.equipment = data;
        return data;
      })
    );

    this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
      this.currentUser = result;
      this.cdr.markForCheck();
    });

    this.editViewButtonClicked = this.editViewEventService.listenForEditButtonClicked().subscribe((result) => {
      this.editMode = result;
      this.cdr.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.currentEquipmentService.setCurrentEquipment(null);
    this.user.unsubscribe();
    this.editViewButtonClicked?.unsubscribe();
    this.deleteSubscription?.unsubscribe();
  }

  refreshEquipment(result: any) {
    this.equipment = result;
    this.currentEquipmentService.setCurrentEquipment(this.equipment);
    this.cdr.detectChanges();
  }

  canDelete() {
    return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.EquipmentRights, RightsEnum.Delete);
  }

  public delete() {
    const dialogRef = this.dialog.open(ConfirmDialog, {
        data: {
            header: "Delete Equipment",
            text: `You are about to delete equipment ${this.equipment.SerialNumber}. This action cannot be undone. Are you sure you wish to proceed?`,
        }
    });

    return dialogRef.afterClosed().subscribe((confirmed) => {
        if (confirmed) {
            this.equipmentService.equipmentEquipmentIDDelete(this.equipment.EquipmentID).subscribe(() => {
              this.alertService.pushAlert(new Alert(`Equipment was successfully deleted.`, AlertContext.Success, true));
              this.router.navigate(["/equipment"]);
            }, error => {
              this.alertService.pushAlert(new Alert(`There was an error deleting the equipment. Please try again.`, AlertContext.Danger, true));
            });
          }
    });
}

  hasReadOnly() {
    const read = this.authenticationService.hasPermission(this?.currentUser, PermissionEnum.EquipmentRights, RightsEnum.Read);
    const update = this.authenticationService.hasPermission(this?.currentUser, PermissionEnum.EquipmentRights, RightsEnum.Update);
    const remove = this.authenticationService.hasPermission(this?.currentUser, PermissionEnum.EquipmentRights, RightsEnum.Delete);
    const create = this.authenticationService.hasPermission(this?.currentUser, PermissionEnum.EquipmentRights, RightsEnum.Create);
    if (read && !update && !remove && !create) {
      return true;
    } else {
      return false;
    }
  }
}
