import * as L from 'leaflet'
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CurrentEquipmentService } from 'src/app/services/current-equipment.service';
import { EquipmentService } from 'src/app/shared/generated/api/equipment.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { EquipmentLocationDto } from 'src/app/shared/generated/model/equipment-location-dto';
import { Alert } from 'src/app/shared/models/alert';
import { EquipmentDto } from 'src/app/shared/generated/model/equipment-dto';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { LocationFormComponent } from 'src/app/pages/shared/location-form/location-form.component';

@Component({
  selector: 'pog-equipment-location-form',
  templateUrl: './../../../../shared/location-form/location-form.component.html',
  styleUrls: ['./equipment-location-form.component.scss']
})
export class EquipmentLocationFormComponent extends LocationFormComponent implements OnInit {
  @Input() public equipment: EquipmentDto;

  constructor(
    private currentEquipmentService: CurrentEquipmentService,
    private equipmentService: EquipmentService,
    private alertService: AlertService,
    authenticationService: AuthenticationService,
    cdr: ChangeDetectorRef
  ) {
    super(authenticationService, cdr);
  }

  ngOnInit(): void {}

  refreshLocation() {
    if (this.equipment?.Latitude && this.equipment?.Longitude) {
      this.location = this.currentEquipmentService.createEquipmentLocationDto(this.equipment);
      const existingLocation = new L.latLng(this.location.Latitude, this.location.Longitude);
      this.placeMarker(existingLocation);
    } else {
      if (!!this.marker) {
        this.map.removeLayer(this.marker);
        this.marker = undefined;
        this.location = new EquipmentLocationDto();
      }
    }
  }

  saveForm() {
    this.equipmentService.equipmentEquipmentIDLocationPut(this.equipment.EquipmentID, this.location)
      .subscribe((result) => {
        this.alertService.pushAlert(new Alert("The equipment location was successfully updated.", AlertContext.Success), 5000);
        this.formSubmitted.emit(result);
      });
  }

}
