<form class="form grid-12" #equipmentForm="ngForm">
    <div class="g-col-6">
        <esa-material-ff
            name="SerialNumber"
            [editMode]="editMode"
            [required]="true"
            type="text"
            [(ngModel)]="equipmentUpsertDto.SerialNumber"
            [customLabel]="SerialNumberCustomLabel"
        >
        </esa-material-ff>
        <ng-template #SerialNumberCustomLabel>
            <pog-custom-form-label
                label="Serial Number"
                fieldDefinitionType="SerialNumber"
                [required]="true"
            ></pog-custom-form-label>
        </ng-template>
    </div>

    <div class="g-col-6">
        <esa-material-ff
            name="SealType"
            [editMode]="editMode"
            [required]="true"
            label="Seal Type"
            type="dropdown"
            [options]="sealTypes$ | async"
            optionLabel="SealTypeDisplayName"
            optionValue="SealTypeID"
            [(ngModel)]="equipmentUpsertDto.SealTypeID"
        >
        </esa-material-ff>
    </div>

    <div class="g-col-6">
        <esa-material-ff
            name="EquipmentType"
            [editMode]="editMode"
            [required]="true"
            type="dropdown"
            [options]="equipmentTypes$ | async"
            optionLabel="EquipmentTypeDisplayName"
            optionValue="EquipmentTypeID"
            [(ngModel)]="equipmentUpsertDto.EquipmentTypeID"
            (ngOnChange)="handleEquipmentTypeChange()"
            [customLabel]="EquipmentTypeCustomLabel"
        >
        </esa-material-ff>
        <ng-template #EquipmentTypeCustomLabel>
            <pog-custom-form-label
                label="Equipment Type"
                fieldDefinitionType="EquipmentType"
                [required]="true"
            ></pog-custom-form-label>
        </ng-template>
    </div>

    <div class="g-col-6">
        <ng-container *ngIf="isEquipmentTypeOtherRequired()">
            <esa-material-ff
                name="EquipmentTypeOther"
                [editMode]="editMode"
                [required]="isEquipmentTypeOtherRequired()"
                type="text"
                [(ngModel)]="equipmentUpsertDto.EquipmentTypeOther"
                [customLabel]="EquipmentTypeOtherCustomLabel"
            >
            </esa-material-ff>
            <ng-template #EquipmentTypeOtherCustomLabel>
                <pog-custom-form-label
                    label="Equipment Type - Other"
                    fieldDefinitionType="EquipmentTypeOther"
                    [required]="true"
                ></pog-custom-form-label>
            </ng-template>
        </ng-container>
        <ng-container *ngIf="!isEquipmentTypeOtherRequired()">
            <ng-template [ngTemplateOutlet]="PlaceholderDiv"></ng-template>
        </ng-container>
    </div>

    <ng-container *ngIf="!!equipmentID">
        <div class="g-col-6">
            <esa-material-ff
                name="Manufacturer"
                [editMode]="editMode"
                [required]="true"
                label="Manufacturer"
                type="dropdown"
                [options]="manufacturers$ | async"
                optionLabel="ManufacturerDisplayName"
                optionValue="ManufacturerID"
                [(ngModel)]="equipmentUpsertDto.ManufacturerID"
            >
            </esa-material-ff>
        </div>

        <div class="g-col-6">
            <esa-material-ff
                name="ManufactureYear"
                [editMode]="editMode"
                [required]="true"
                label="Year Manufactured"
                type="text"
                [(ngModel)]="equipmentUpsertDto.ManufactureYear"
            >
            </esa-material-ff>
        </div>

        <div class="g-col-6">
            <esa-material-ff
                name="VoltageCapacity"
                [editMode]="editMode"
                [required]="true"
                label="Voltage Capacity (kV)"
                type="text"
                (input)="validateNumberForProperty($event, 'VoltageCapacity')"
                (focusout)="validateDecimalPlaces('VoltageCapacity')"
                [(ngModel)]="equipmentUpsertDto.VoltageCapacity"
            >
            </esa-material-ff>
        </div>

        <div class="g-col-6">
            <esa-material-ff
                name="NameplateCapacity"
                [editMode]="editMode"
                [required]="true"
                type="text"
                [(ngModel)]="equipmentUpsertDto.NameplateCapacity"
                (input)="validateNumberForProperty($event, 'NameplateCapacity')"
                (focusout)="validateDecimalPlaces('NameplateCapacity')"
                [customLabel]="NameplateCapacityCustomLabel"
            >
            </esa-material-ff>
            <ng-template #NameplateCapacityCustomLabel>
                <pog-custom-form-label
                    label="Nameplate Capacity (lb)"
                    fieldDefinitionType="NameplateCapacity"
                    [required]="true"
                ></pog-custom-form-label>
            </ng-template>
        </div>

        <div class="g-col-6">
            <esa-material-ff
                name="CoveredInsulatingGas"
                [editMode]="editMode"
                [required]="true"
                label="Covered Insulating Gas"
                type="dropdown"
                [options]="coveredInsulatingGases$ | async"
                optionLabel="CoveredInsulatingGasDisplayName"
                optionValue="CoveredInsulatingGasID"
                [(ngModel)]="equipmentUpsertDto.CoveredInsulatingGasID"
            >
            </esa-material-ff>
        </div>

        <div class="g-col-6">
            <esa-material-ff
                name="EquipmentStatus"
                [editMode]="editMode"
                type="dropdown"
                label="Status"
                [required]="true"
                [options]="equipmentStatuses$ | async"
                optionLabel="EquipmentStatusDisplayName"
                optionValue="EquipmentStatusID"
                [(ngModel)]="equipmentUpsertDto.EquipmentStatusID"
                (ngModelChange)="handleEquipmentStatusChange($event)"
                [customLabel]="EquipmentStatusCustomLabel"
            >
            </esa-material-ff>
            <ng-template #EquipmentStatusCustomLabel>
                <pog-custom-form-label
                    label="Equipment Status"
                    fieldDefinitionType="EquipmentStatus"
                    [required]="true"
                ></pog-custom-form-label>
            </ng-template>
        </div>

        <div class="g-col-6">
            <ng-container *ngIf="isRetirementDateRequired()">
                <esa-material-ff
                    name="RetirementDate"
                    [editMode]="editMode"
                    type="date"
                    [required]="isRetirementDateRequired()"
                    [(ngModel)]="equipmentUpsertDto.RetirementDate"
                    [customLabel]="RetirementDateCustomLabel"
                >
                </esa-material-ff>
                <ng-template #RetirementDateCustomLabel>
                    <pog-custom-form-label
                        label="Retirement Date"
                        fieldDefinitionType="RetirementDate"
                        [required]="true"
                    ></pog-custom-form-label>
                </ng-template>
            </ng-container>
            <ng-container *ngIf="!isRetirementDateRequired()">
                <ng-template [ngTemplateOutlet]="PlaceholderDiv"></ng-template>
            </ng-container>
        </div>

        <div class="g-col-6">
            <ng-container *ngIf="isRetirementDateRequired()">
                <esa-material-ff
                    name="GasTransferredOutAmount"
                    [editMode]="editMode"
                    type="text"
                    (input)="
                        validateNumberForProperty(
                            $event,
                            'GasTransferredOutAmount'
                        )
                    "
                    (focusout)="
                        validateDecimalPlaces('GasTransferredOutAmount')
                    "
                    [(ngModel)]="equipmentUpsertDto.GasTransferredOutAmount"
                    [customLabel]="GasTransferredOutAmountCustomLabel"
                >
                </esa-material-ff>
                <ng-template #GasTransferredOutAmountCustomLabel>
                    <pog-custom-form-label
                        label="Amount of Gas Transferred Out (lb)"
                        fieldDefinitionType="GasTransferredOutAmount"
                    ></pog-custom-form-label>
                </ng-template>
            </ng-container>
            <ng-container *ngIf="!isRetirementDateRequired()">
                <ng-template [ngTemplateOutlet]="PlaceholderDiv"></ng-template>
            </ng-container>
        </div>

        <div class="g-col-6">
            <esa-material-ff
                name="Department"
                [editMode]="editMode"
                [required]="true"
                label="Department"
                type="dropdown"
                [options]="departments$ | async"
                optionLabel="DepartmentDisplayName"
                optionValue="DepartmentID"
                [(ngModel)]="equipmentUpsertDto.DepartmentID"
            >
            </esa-material-ff>
        </div>

        <div class="g-col-6">
            <esa-material-ff
                name="Address"
                [editMode]="editMode"
                type="text"
                label="Address"
                [(ngModel)]="equipmentUpsertDto.Address"
            >
            </esa-material-ff>
        </div>

        <div class="g-col-6">
            <esa-material-ff
                name="ContactName"
                [editMode]="editMode"
                type="text"
                label="Contact Name"
                placeholder="First Name Last Name"
                [(ngModel)]="equipmentUpsertDto.ContactName"
            >
            </esa-material-ff>
        </div>

        <div class="g-col-6">
            <esa-material-ff
                name="ContactEmail"
                [editMode]="editMode"
                type="text"
                label="Contact Email"
                [(ngModel)]="equipmentUpsertDto.ContactEmail"
            >
            </esa-material-ff>
        </div>

        <div class="g-col-6">
            <esa-material-ff
                name="ContactPhone"
                [editMode]="editMode"
                type="text"
                placeholder="###-###-####"
                label="Contact Cell Phone Number"
                [(ngModel)]="equipmentUpsertDto.ContactPhone"
            >
            </esa-material-ff>
        </div>
    </ng-container>

    <div class="required-note g-col-12">
        <i class="fas fa-bolt"></i> Required field
    </div>

    <div class="g-col-12 actions-bar" *ngIf="editMode" style="margin-top: 1rem">
        <esa-material-button
            style="margin-right: 1rem"
            type="primary"
            label="Save"
            icon="save"
            [tooltip]="
                !equipmentForm.form.valid
                    ? 'You are missing required fields.'
                    : ''
            "
            (click)="saveForm(form)"
            [disabled]="!equipmentForm.form.valid"
        >
        </esa-material-button>

        <esa-material-button
            type="secondary"
            label="Cancel"
            icon="cancel"
            (click)="cancelEditMode()"
        >
        </esa-material-button>
    </div>
</form>

<ng-template #PlaceholderDiv>
    <div style="width: 100%; height: 4rem"></div>
</ng-template>
