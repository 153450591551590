import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { RoleEnum } from 'src/app/shared/generated/enum/role-enum';
import { environment } from 'src/environments/environment';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-home-index',
    templateUrl: './home-index.component.html',
    styleUrls: ['./home-index.component.scss']
})
export class HomeIndexComponent implements OnInit, OnDestroy {
    
    public currentUser: UserDto;

    public richTextTypeID: number = CustomRichTextTypeEnum.Homepage;

    routeSubscription: Subscription;
    userSubscription: Subscription;

    homeImages = ['pog1.jpg', 'pog2.jpg'];
    homeImagePath = '../assets/main';

    constructor(private authenticationService: AuthenticationService,
        private router: Router,
        private route: ActivatedRoute) {
    }

    buildHomeImagePath() {
        const homeImagesIndex = Math.floor(Math.random() * this.homeImages.length);
        this.homeImagePath = `${this.homeImagePath}/${this.homeImages[homeImagesIndex]}`; 
    }

    public ngOnInit(): void {
        this.buildHomeImagePath();
        this.routeSubscription = this.route.queryParams.subscribe(params => {
            //We're logging in
            if (params.hasOwnProperty("code")) {
                this.router.navigate(["/signin-oidc"], { queryParams: params });
                return;
            }

            if (localStorage.getItem("loginOnReturn")) {
                localStorage.removeItem("loginOnReturn");
                this.authenticationService.login();
            }

            //We were forced to logout or were sent a link and just finished logging in
            if (sessionStorage.getItem("authRedirectUrl")) {
                this.router.navigateByUrl(sessionStorage.getItem("authRedirectUrl"))
                    .then(() => {
                        sessionStorage.removeItem("authRedirectUrl");
                    });
            }

            this.userSubscription = this.authenticationService.getCurrentUser().subscribe(currentUser => {
                this.currentUser = currentUser;
            });

        });
    }

    ngOnDestroy(): void {
        this.userSubscription?.unsubscribe();
        this.routeSubscription?.unsubscribe();
    }

    public userIsUnassigned() {
        if (!this.currentUser) {
            return false; // doesn't exist != unassigned
        }

        return this.currentUser.Role.RoleID === RoleEnum.NoAccess && this.currentUser.IsActive;
    }

    public userRoleIsDisabled() {
        if (!this.currentUser) {
            return false; // doesn't exist != unassigned
        }

        return this.currentUser.Role.RoleID === RoleEnum.NoAccess && !this.currentUser.IsActive;
    }

    public login(): void {
        this.authenticationService.login();
    }


    public platformLongName(): string {
        return environment.platformLongName;
    }

    requestSupport() {
        this.router.navigateByUrl("/help");
    }
}
