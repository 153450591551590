/**
 * Pog.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export class CanisterUpsertDto { 
    VesselTypeID: number;
    PropertyOfGieStatusID: number;
    GasContainerOrGasCartID: string;
    GasContainerNickname?: string;
    CoveredInsulatingGasID: number;
    CanisterStatusID: number;
    TareWeight?: number;
    RetirementDate?: string;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}
