/**
 * Pog.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CanisterDto } from '../model/canister-dto';
import { CanisterLocationDto } from '../model/canister-location-dto';
import { CanisterUpsertDto } from '../model/canister-upsert-dto';
import { CanisterYearEndWeightDto } from '../model/canister-year-end-weight-dto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../services';


@Injectable({
  providedIn: 'root'
})
export class CanisterService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param canisterID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public canistersCanisterIDDelete(canisterID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public canistersCanisterIDDelete(canisterID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public canistersCanisterIDDelete(canisterID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public canistersCanisterIDDelete(canisterID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (canisterID === null || canisterID === undefined) {
            throw new Error('Required parameter canisterID was null or undefined when calling canistersCanisterIDDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/canisters/${encodeURIComponent(String(canisterID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param canisterID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public canistersCanisterIDGet(canisterID: number, observe?: 'body', reportProgress?: boolean): Observable<CanisterDto>;
    public canistersCanisterIDGet(canisterID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CanisterDto>>;
    public canistersCanisterIDGet(canisterID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CanisterDto>>;
    public canistersCanisterIDGet(canisterID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (canisterID === null || canisterID === undefined) {
            throw new Error('Required parameter canisterID was null or undefined when calling canistersCanisterIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<CanisterDto>(`${this.basePath}/canisters/${encodeURIComponent(String(canisterID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param canisterID 
     * @param canisterLocationDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public canistersCanisterIDLocationPut(canisterID: number, canisterLocationDto?: CanisterLocationDto, observe?: 'body', reportProgress?: boolean): Observable<CanisterDto>;
    public canistersCanisterIDLocationPut(canisterID: number, canisterLocationDto?: CanisterLocationDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CanisterDto>>;
    public canistersCanisterIDLocationPut(canisterID: number, canisterLocationDto?: CanisterLocationDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CanisterDto>>;
    public canistersCanisterIDLocationPut(canisterID: number, canisterLocationDto?: CanisterLocationDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (canisterID === null || canisterID === undefined) {
            throw new Error('Required parameter canisterID was null or undefined when calling canistersCanisterIDLocationPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CanisterDto>(`${this.basePath}/canisters/${encodeURIComponent(String(canisterID))}/location`,
            canisterLocationDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param canisterID 
     * @param canisterUpsertDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public canistersCanisterIDPut(canisterID: number, canisterUpsertDto?: CanisterUpsertDto, observe?: 'body', reportProgress?: boolean): Observable<CanisterDto>;
    public canistersCanisterIDPut(canisterID: number, canisterUpsertDto?: CanisterUpsertDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CanisterDto>>;
    public canistersCanisterIDPut(canisterID: number, canisterUpsertDto?: CanisterUpsertDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CanisterDto>>;
    public canistersCanisterIDPut(canisterID: number, canisterUpsertDto?: CanisterUpsertDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (canisterID === null || canisterID === undefined) {
            throw new Error('Required parameter canisterID was null or undefined when calling canistersCanisterIDPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CanisterDto>(`${this.basePath}/canisters/${encodeURIComponent(String(canisterID))}`,
            canisterUpsertDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public canistersGet(observe?: 'body', reportProgress?: boolean): Observable<Array<CanisterDto>>;
    public canistersGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CanisterDto>>>;
    public canistersGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CanisterDto>>>;
    public canistersGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<CanisterDto>>(`${this.basePath}/canisters`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public canistersLocationsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<CanisterLocationDto>>;
    public canistersLocationsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CanisterLocationDto>>>;
    public canistersLocationsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CanisterLocationDto>>>;
    public canistersLocationsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<CanisterLocationDto>>(`${this.basePath}/canisters/locations`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param canisterUpsertDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public canistersPost(canisterUpsertDto?: CanisterUpsertDto, observe?: 'body', reportProgress?: boolean): Observable<CanisterDto>;
    public canistersPost(canisterUpsertDto?: CanisterUpsertDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CanisterDto>>;
    public canistersPost(canisterUpsertDto?: CanisterUpsertDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CanisterDto>>;
    public canistersPost(canisterUpsertDto?: CanisterUpsertDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CanisterDto>(`${this.basePath}/canisters`,
            canisterUpsertDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param yearID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public canistersReportingYearIDGet(yearID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<CanisterYearEndWeightDto>>;
    public canistersReportingYearIDGet(yearID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CanisterYearEndWeightDto>>>;
    public canistersReportingYearIDGet(yearID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CanisterYearEndWeightDto>>>;
    public canistersReportingYearIDGet(yearID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (yearID === null || yearID === undefined) {
            throw new Error('Required parameter yearID was null or undefined when calling canistersReportingYearIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<CanisterYearEndWeightDto>>(`${this.basePath}/canisters/reporting/${encodeURIComponent(String(yearID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

}
