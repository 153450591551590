import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { YearDto } from 'src/app/shared/generated/model/year-dto';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'pog-report-form',
  templateUrl: './report-form.component.html',
  styleUrls: ['./report-form.component.scss']
})
export class ReportFormComponent implements OnInit {
  @ViewChild("reportForm", { read: NgForm }) form: NgForm;
  
  @Output() formSubmitted = new EventEmitter<any>();
  @Input() years: YearDto[];

  @Input() annualReportYear: number;

  editMode = true;

  constructor(
    private alertService: AlertService,
    private cdr: ChangeDetectorRef
    ) {}

  ngOnInit(): void {
  }

  submitReportYear(form: NgForm) {
    this.formSubmitted.emit(this.annualReportYear);
  }

}