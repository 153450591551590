<div class="home-splash">

    <div class="photo">
        <img [src]="homeImagePath" alt="Port of Oakland GIE" />
    </div>

    <div class="info">
        <div *ngIf="userIsUnassigned()" class="copy copy-2">
            <p>Welcome {{currentUser.FullName}}. You have successfully logged in to the {{platformLongName()}},
                but your account is not yet configured. You will receive an email from the Administrators when your account is
                ready to use. You may <a routerLink="/help">Request Support</a> to contact the Administrators with any questions or
                comments.</p>
        </div>

        <div *ngIf="userRoleIsDisabled()">
            <mat-card style="background: lightyellow; margin-bottom: 1rem;">
                <mat-card-content>
                    <span>
                        Your account is currently disabled. Please <a routerLink="/help">Request Support</a> if you would like to
                        activate your account.
                    </span>
                </mat-card-content>
              </mat-card>
        </div>

        <h1 class="page-title">{{platformLongName()}}</h1>

        <div class="copy copy-2" style="padding-bottom: 1.5rem;">
            <custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
        </div>

        <div class="card mt-4">
            <div class="card-header">
                <h3 class="card-title">
                    <ng-container *ngIf="!currentUser">Access</ng-container>
                    <ng-container *ngIf="currentUser">Quick actions</ng-container>
                </h3>
            </div>
            <div class="card-body" *ngIf="!currentUser">
                <div class="copy copy-3">
                    <p>Sign in to view your Account. Create an Account if you don't have one yet.</p>
                </div>

                <div class="mt-2">
                    <esa-material-button type="primary" label="Sign In" aria-label="Sign In" (click)="login()"></esa-material-button>
                    <esa-material-button type="primary" label="Create Account" aria-label="Create Account" (click)="login()" style="margin-left: 1rem;"></esa-material-button>
                </div>
            </div>

            <div class="card-body" *ngIf="currentUser">
                <esa-material-button type="primary" label="Request Support" (click)="requestSupport()"></esa-material-button>
            </div>
        </div>
    </div>
</div>