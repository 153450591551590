import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CurrentProjectService } from 'src/app/services/current-project.service';
import { ProjectDto } from 'src/app/shared/generated/model/project-dto';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ProjectFileService } from 'src/app/shared/generated/api/project-file.service';
import { ButtonRendererComponent } from 'src/app/shared/components/ag-grid/button-renderer/button-renderer.component';
import { DateColumnCreatorService } from 'src/app/shared/services/date-column-creator/date-column-creator.service';
import { PermissionEnum } from 'src/app/shared/generated/enum/permission-enum';
import { RightsEnum } from 'src/app/shared/models/enums/rights.enum';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { ConfirmDialog } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FileService } from 'src/app/shared/services/file/file.service';

@Component({
    selector: 'pog-project-file-list',
    templateUrl: './project-file-list.component.html',
    styleUrls: ['./project-file-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectFileListComponent implements OnInit, OnDestroy {
    @ViewChild("projectFileGrid") projectFileGrid: AgGridAngular;

    public project: ProjectDto;
    public currentUser: UserDto;
    public fileRoute: string;
    public rowData: any[];
    public columnDefs: ColDef[];
    public defaultColDef: any;
    public frameworkComponents: any;
    public isLoading: Boolean = false;
    public project$: Observable<any>;
    public hasUnuploadedFiles: Boolean = false;

    currentUserSubscription: Subscription;
    projectFilesSubscription: Subscription;

    constructor(
        private projectFileService: ProjectFileService,
        private fileService: FileService,
        private currentProjectService: CurrentProjectService,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private dateColumnCreatorService: DateColumnCreatorService,
        private cdr: ChangeDetectorRef,
        public dialog: MatDialog
    ) {
        this.defaultColDef = {
            sortable: true,
            filter: true,
            resizable: true,
            floatingFilter: true,
            suppressMenu: true,
        };

        this.frameworkComponents = {
            buttonRenderer: ButtonRendererComponent,
        };
    }

    ngOnInit(): void {
        this.currentUserSubscription = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;

            this.currentProjectService.currentProject$.subscribe((result) => {
                this.project = result;
                this.columnDefs = [
                    {
                        cellRenderer: "buttonRenderer",
                        cellRendererParams: {
                            onClick: this.downloadFile.bind(this),
                            icon: "download",
                        },
                        cellStyle: (params) => {
                            return { "text-overflow": "unset" };
                        },
                        filter: null,
                        sortable: false,
                        width: 110
                    },
                    {
                        headerName: "Name",
                        field: "Name",
                        tooltipField: "Name",
                        flex: 4,
                        sort: "asc",
                    },
                    {
                        headerName: "Size",
                        field: "$ObjectSize",
                        flex: 1,
                    },
                    {
                        headerName: "Uploaded By",
                        field: "$UploadedBy",
                        flex: 2,
                    },
                    this.dateColumnCreatorService.createDateColumnDef("Uploaded On", "$UploadedOn", "M/dd/YYYY"),
                ];
            
                if (this.canDeleteProjectFile(this.currentUser)) {
                    this.columnDefs.push(
                        {
                            cellRenderer: "buttonRenderer",
                            cellRendererParams: {
                                onClick: this.deleteFile.bind(this),
                                icon: "delete",
                                type: "clear",
                            },
                            cellStyle: (params) => {
                                return { "text-overflow": "unset" };
                            },
                            filter: null,
                            sortable: false,
                            width: 110
                        }
                    )
                }
    
                this.refreshData();
            });
        });
    }

    onProjectFileGridReady(event) {
        this.projectFileGrid.api.hideOverlay();
    }
    
    refreshData() {
        if (this.project) {
            this.fileRoute = `projects/${this.project.ProjectID}/files`;
            this.isLoading = true;

            this.projectFilesSubscription = this.projectFileService.projectsProjectIDFilesGet(this.project.ProjectID).subscribe((result) => {
                this.rowData = result;
    
                this.rowData.forEach((file) => {
                    file.$UploadedBy = file?.UpdateUserName ?? file?.CreateUserName;
                    file.$UploadedOn = file?.UpdateDate ?? file?.CreateDate;
                    file.$ObjectSize = this.formatFileSize(file.ObjectSize);
                });
    
                this.isLoading = false;
                this.cdr.markForCheck();
            });
        }
    }

    // Uploading and deleting ProjectFiles are considered updating the parent Project
    canUploadProjectFile(currentUser: UserDto) {
        return this.authenticationService.hasPermission(currentUser, PermissionEnum.ProjectRights, RightsEnum.Update);
    }

    canDeleteProjectFile(currentUser: UserDto) {
        return this.authenticationService.hasPermission(currentUser, PermissionEnum.ProjectRights, RightsEnum.Update);
    }

    hasFilesToUpload(event) {
        this.hasUnuploadedFiles = event;
    }

    fileUploadStarted() {
        this.isLoading = true;
        this.cdr.markForCheck();
    }

    fileUploadSuccess() {
        this.refreshData();
        this.alertService.pushAlert(new Alert("The file(s) were successfully uploaded.", AlertContext.Success))
    }
    
    downloadFile(event) {
        this.fileService.downloadFile(`${this.fileRoute}/${event.rowData.BlobName}`).subscribe((result) => {
            if (!result) {
                return;
            }

            const file = new File([result], event.rowData.Name);
            if (navigator.msSaveBlob) {
                return navigator.msSaveBlob(file, file.name);
            }

            this.downloadAsHref(URL.createObjectURL(file), file.name);
        });
    }

    downloadAsHref(href: string, filename: string) {
        const tempDownloadLink = document.createElement("a");
        tempDownloadLink.href = href;
        tempDownloadLink.download = filename;

        document.body.appendChild(tempDownloadLink);
        tempDownloadLink.click();
        document.body.removeChild(tempDownloadLink);
    }

    deleteFile(event) {
        const dialogRef = this.dialog.open(ConfirmDialog, {
            data: {
                header: "Delete Project File",
                text: `You are about to delete the file ${event.rowData.Name}. This action cannot be undone. Are you sure you wish to proceed?`,
            }
        });
    
        return dialogRef.afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                this.projectFileService.projectsProjectIDFilesBlobNameDelete(this.project.ProjectID, event.rowData.BlobName).subscribe(() => {
                  this.alertService.pushAlert(new Alert(`Project File was successfully deleted.`, AlertContext.Success, true));
                  this.refreshData();
                }, error => {
                  this.alertService.pushAlert(new Alert(`There was an error deleting the project file. Please try again.`, AlertContext.Danger, true));
                });
            }
        });
    }

    formatFileSize(bytes, decimals = 2) {
        if (!+bytes) return "0 Bytes";

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    }

    ngOnDestroy(): void {
        this.currentUserSubscription?.unsubscribe();
        this.projectFilesSubscription?.unsubscribe();
    }

    canExit() {
        return !this.isLoading && !this.hasUnuploadedFiles;
    }
}
