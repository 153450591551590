import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { CanisterService } from 'src/app/shared/generated/api/canister.service';
import { YearService } from 'src/app/shared/generated/api/year.service';
import { ReportBaseGenerateComponent } from '../shared/report-base-generate/report-base-generate.component';
import { CustomDropdownFilterComponent } from 'src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component';
import { LinkRendererComponent } from 'src/app/shared/components/ag-grid/link-renderer/link-renderer.component';
import { DateColumnCreatorService } from 'src/app/shared/services/date-column-creator/date-column-creator.service';

@Component({
  selector: 'pog-report-canister-generate',
  templateUrl: './report-canister-generate.component.html',
  styleUrls: ['./report-canister-generate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportCanisterGenerateComponent extends ReportBaseGenerateComponent implements OnInit {
  @ViewChild("CanisterGrid") canisterGrid: AgGridAngular;

  constructor(
    private canisterService: CanisterService,
    private dateColumnCreator: DateColumnCreatorService,
    yearService: YearService,
    cdr: ChangeDetectorRef
  ) { 
    super(yearService, cdr);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  setColumnDefinitions() {
    this.columnDefs = [
        {
          headerName: "Vessel Type",
          field: "Canister.VesselType.VesselTypeDisplayName",
          tooltipField: "VesselType.VesselTypeDisplayName",
          filterFramework: CustomDropdownFilterComponent,
          filterParams: {
              field: "Canister.VesselType.VesselTypeDisplayName",
          },
          minWidth: 200,
      },
      {
          headerName: "Property of GIE Owner",
          field: "Canister.PropertyOfGieStatus.PropertyOfGieStatusDisplayName",
          tooltipField: "Canister.PropertyOfGieStatus.PropertyOfGieStatusDisplayName",
          filterFramework: CustomDropdownFilterComponent,
          filterParams: {
              field: "Canister.PropertyOfGieStatus.PropertyOfGieStatusDisplayName",
          },
          minWidth: 200,
      },
      {
        headerName: "Gas Container or Gas Cart ID",
        valueGetter: function (params: any) {
            return {
                LinkValue: params.data.Canister.CanisterID,
                LinkDisplay: params.data.Canister.GasContainerOrGasCartID,
            };
        },
        cellRendererFramework: LinkRendererComponent,
        cellRendererParams: { inRouterLink: "/canisters/" },
        filterValueGetter: function (params: any) {
            return params.node.rowPinned
                ? null
                : params.data.Canister.GasContainerOrGasCartID;
        },
        comparator: function (
            linkA,
            linkB,
            nodeA,
            nodeB,
            isDescending
        ) {
            let valueA = linkA.LinkDisplay.toLowerCase();
            let valueB = linkB.LinkDisplay.toLowerCase();

            return valueA.localeCompare(valueB, undefined, {
                numeric: true,
                sensitivity: "base",
            });
        },
        minWidth: 250,
        sort: "asc",
      },
      {
        headerName: "Covered Insulating Gas",
        field: "Canister.CoveredInsulatingGas.CoveredInsulatingGasDisplayName",
        tooltipField: "CoveredInsulatingGas.CoveredInsulatingGasDisplayName",
        filterFramework: CustomDropdownFilterComponent,
        filterParams: {
            field: "Canister.CoveredInsulatingGas.CoveredInsulatingGasDisplayName",
        },
        minWidth: 200,
      },
      {
        headerName: "Covered Insulating Gas' GWP",
        minWidth: 225,
      },
      this.dateColumnCreator.createDateColumnDef("Date Gas Container Weighed (mm/dd/yyyy)", "WeightDate", "MM/dd/YYYY", 350),
      {
        headerName: "Reason of Measuring Amount of Gas Inside Gas Container or Gas Cart",
        field: "ReasonForMeasurementDisplayName",
        minWidth: 525,
      },
      {
        headerName: "Amount of Covered Insulating Gas in Gast Container or Gas Cart when Measured (lb)",
        field: "Weight",
        filter: 'agNumberColumnFilter',
        minWidth: 650,
      },
      {
        headerName: "Covered Insulating Gas in Gas Container or Gas Cart at the Beginning of the Data Year (lb, if applicable)",
        field: "PreviousYearWeight",
        filter: 'agNumberColumnFilter',
        minWidth: 750,
      },
    ];
  }

  refreshGrid() {
      this.canisterGrid.api.showLoadingOverlay();
      this.getReportDataRequest = this.canisterService.canistersReportingYearIDGet(this.annualReportYearID)
          .subscribe((results) => {
              this.rowData = results.filter(r => !r.Canister.RetirementDate || new Date(r.Canister.RetirementDate) >= new Date(`01/01/${this.annualReportYear}`));
              this.rowData.forEach(r => {
                if (!!r.WeightDate) {
                  r.WeightDate = new Date(r.WeightDate).toLocaleDateString();
                }
              });
              this.canisterGrid.api.hideOverlay();
              this.cdr.markForCheck();
          });
  }
}
