<div class="list-view">
    <div class="page-header">
        <div class="actions-bar flex-between">
            <h1 class="page-title">Equipment</h1>
            <esa-material-button *ngIf="canCreateEquipment" label="Equipment" icon="add" type="primary" (click)="navigateToEquipmentCreatePage()">
            </esa-material-button>
        </div>
        <div class="header-links">
            <div class="header-link">
                <a
                    href=""
                    (click)="downloadFile($event, 'AirportEquipmentandCanisterPictures2021')">
                    Airport Equipment and Canister Pictures 2021
                </a>
            </div>
            <div class="header-link">
                <a
                    href=""
                    (click)="downloadFile($event, 'HarborEquipmentandCanisterPictures2021')">
                    Harbor Equipment and Canister Pictures 2021
                </a>
            </div>
        </div>
    </div>

    
    <div class="filter-btn-group align-right mb-3">
        <pog-clear-grid-filters-button class="mr-2" [grid]="equipmentGrid"></pog-clear-grid-filters-button>
        <pog-csv-download-button [grid]="equipmentGrid" fileName="equipment" colIDsToExclude=""></pog-csv-download-button>
    </div>

    <div>
        <div class="table-responsive">
            <ag-grid-angular
                #EquipmentGrid
                style="width: 100%; height: 100%"
                class="ag-theme-balham"
                [rowData]="rowData"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                [suppressMenuHide]="true"
                (gridReady)="onEquipmentGridReady($event)"
                (firstDataRendered)="onFirstDataRendered($event)">
            </ag-grid-angular>
        </div>
    </div>
</div>
