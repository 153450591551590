import * as L from 'leaflet'
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { LocationFormComponent } from 'src/app/pages/shared/location-form/location-form.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CurrentCanisterService } from 'src/app/services/current-canister.service';
import { CanisterService } from 'src/app/shared/generated/api/canister.service';
import { CanisterDto } from 'src/app/shared/generated/model/canister-dto';
import { CanisterLocationDto } from 'src/app/shared/generated/model/canister-location-dto';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
    selector: 'pog-canister-location-form',
    templateUrl: './../../../../shared/location-form/location-form.component.html',
    styleUrls: ['./canister-location-form.component.scss']
})
export class CanisterLocationFormComponent extends LocationFormComponent implements OnInit {
    @Input() public canister: CanisterDto;

    constructor(
        private currentCanisterService: CurrentCanisterService,
        private canisterService: CanisterService,
        private alertService: AlertService,
        authenticationService: AuthenticationService,
        cdr: ChangeDetectorRef
    ) {
        super(authenticationService, cdr);
    }

    ngOnInit(): void { }

    refreshLocation() {
        if (this.canister?.Latitude && this.canister?.Longitude) {
            this.location = this.currentCanisterService.createCanisterLocationDto(this.canister);
            const existingLocation = new L.latLng(this.location.Latitude, this.location.Longitude);
            this.placeMarker(existingLocation);
        } else {
            if (!!this.marker) {
                this.resetMap();
                this.location = new CanisterLocationDto();
            }
        }
    }

    saveForm() {
        this.canisterService.canistersCanisterIDLocationPut(this.canister.CanisterID, this.location)
            .subscribe((result) => {
                this.alertService.pushAlert(new Alert("The canister location was successfully updated.", AlertContext.Success), 5000);
                this.formSubmitted.emit(result);
            });
    }

}

