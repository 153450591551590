<div class="list-view">
    <div class="page-header">
        <div class="actions-bar flex-between">
            <h1 class="page-title">Projects</h1>
            <esa-material-button *ngIf="canCreateProject" label="Project" icon="add" type="primary" (click)="navigateToProjectCreatePage()">
            </esa-material-button>
        </div>
    </div>

    
    <div class="filter-btn-group align-right mb-3">
        <pog-clear-grid-filters-button class="mr-2" [grid]="projectsGrid"></pog-clear-grid-filters-button>
        <pog-csv-download-button [grid]="projectsGrid" fileName="projects" colIDsToExclude=""></pog-csv-download-button>
    </div>

    <div>
        <div class="table-responsive">
            <ag-grid-angular
                #ProjectsGrid
                style="width: 100%; height: 100%"
                class="ag-theme-balham"
                [rowData]="rowData"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                [suppressMenuHide]="true"
                (gridReady)="onProjectsGridReady($event)">
            </ag-grid-angular>
        </div>
    </div>
</div>
