import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { Observable, Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CurrentCanisterService } from 'src/app/services/current-canister.service';
import { ButtonRendererComponent } from 'src/app/shared/components/ag-grid/button-renderer/button-renderer.component';
import { ConfirmDialog } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { CanisterFileService } from 'src/app/shared/generated/api/canister-file.service';
import { PermissionEnum } from 'src/app/shared/generated/enum/permission-enum';
import { CanisterDto } from 'src/app/shared/generated/model/canister-dto';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { RightsEnum } from 'src/app/shared/models/enums/rights.enum';
import { ApiService } from 'src/app/shared/services';
import { AlertService } from 'src/app/shared/services/alert.service';
import { DateColumnCreatorService } from 'src/app/shared/services/date-column-creator/date-column-creator.service';
import { FileService } from 'src/app/shared/services/file/file.service';

@Component({
  selector: 'pog-canister-file-list',
  templateUrl: './canister-file-list.component.html',
  styleUrls: ['./canister-file-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CanisterFileListComponent implements OnInit, OnDestroy {
  @ViewChild("canisterFileGrid") canisterFileGrid: AgGridAngular;

  public canister: CanisterDto;
  public currentUser: UserDto;
  public fileRoute: string;
  public rowData: any[];
  public columnDefs: ColDef[];
  public defaultColDef: any;
  public frameworkComponents: any;
  public isLoading: Boolean = false;
  public canister$: Observable<any>;
  public hasUnuploadedFiles: Boolean = false;

  currentUserSubscription: Subscription;
  canisterFilesSubscription: Subscription;

  constructor(
      private canisterFileService: CanisterFileService,
      private fileService: FileService,
      private currentCanisterService: CurrentCanisterService,
      private authenticationService: AuthenticationService,
      private alertService: AlertService,
      private dateColumnCreatorService: DateColumnCreatorService,
      private cdr: ChangeDetectorRef,
      public dialog: MatDialog,
      private apiService: ApiService
  ) {
      this.defaultColDef = {
          sortable: true,
          filter: true,
          resizable: true,
          floatingFilter: true,
          suppressMenu: true,
      };

      this.frameworkComponents = {
          buttonRenderer: ButtonRendererComponent,
      };
  }

  ngOnInit(): void {
      this.currentUserSubscription = this.authenticationService.getCurrentUser().subscribe((result) => {
          this.currentUser = result;

          this.currentCanisterService.currentCanister$.subscribe((result) => {
              this.canister = result;
              this.columnDefs = [
                  {
                      cellRenderer: "buttonRenderer",
                      cellRendererParams: {
                          onClick: this.downloadFile.bind(this),
                          icon: "download",
                      },
                      cellStyle: (params) => {
                          return { "text-overflow": "unset" };
                      },
                      filter: null,
                      sortable: false,
                      width: 110
                  },
                  {
                      headerName: "Name",
                      field: "Name",
                      tooltipField: "Name",
                      flex: 4,
                      sort: "asc",
                  },
                  {
                      headerName: "Size",
                      field: "$ObjectSize",
                      flex: 1,
                  },
                  {
                      headerName: "Uploaded By",
                      field: "$UploadedBy",
                      flex: 2,
                  },
                  this.dateColumnCreatorService.createDateColumnDef("Uploaded On", "$UploadedOn", "M/dd/YYYY"),
              ];
          
              if (this.canDeleteCanisterFile(this.currentUser)) {
                  this.columnDefs.push(
                      {
                          cellRenderer: "buttonRenderer",
                          cellRendererParams: {
                              onClick: this.deleteFile.bind(this),
                              icon: "delete",
                              type: "clear",
                          },
                          cellStyle: (params) => {
                              return { "text-overflow": "unset" };
                          },
                          filter: null,
                          sortable: false,
                          width: 110
                      }
                  )
              }
  
              this.refreshData();
          });
      });
  }

  onCanisterFileGridReady(event) {
      this.canisterFileGrid.api.hideOverlay();
  }
  
  refreshData() {
      if (this.canister) {
          this.fileRoute = `canisters/${this.canister.CanisterID}/files`;
          this.isLoading = true;

          this.canisterFilesSubscription = this.canisterFileService.canistersCanisterIDFilesGet(this.canister.CanisterID).subscribe((result) => {
              this.rowData = result;
  
              this.rowData.forEach((file) => {
                  file.$UploadedBy = file?.UpdateUserName ?? file?.CreateUserName;
                  file.$UploadedOn = file?.UpdateDate ?? file?.CreateDate;
                  file.$ObjectSize = this.formatFileSize(file.ObjectSize);
              });
  
              this.isLoading = false;
              this.cdr.markForCheck();
          });
      }
  }

  // Uploading and deleting CanisterFiles are considered updating the parent Canister
  canUploadCanisterFile(currentUser: UserDto) {
      return this.authenticationService.hasPermission(currentUser, PermissionEnum.CanisterRights, RightsEnum.Update);
  }

  canDeleteCanisterFile(currentUser: UserDto) {
      return this.authenticationService.hasPermission(currentUser, PermissionEnum.CanisterRights, RightsEnum.Update);
  }

  hasFilesToUpload(event) {
      this.hasUnuploadedFiles = event;
  }

  fileUploadStarted() {
      this.isLoading = true;
      this.cdr.markForCheck();
  }

  fileUploadSuccess() {
      this.refreshData();
      this.alertService.pushAlert(new Alert("The file(s) were successfully uploaded.", AlertContext.Success))
  }

  fileUploadFailure(error) {
      this.apiService.handleError(error);
  }
  
  downloadFile(event) {
      this.fileService.downloadFile(`${this.fileRoute}/${event.rowData.BlobName}`).subscribe((result) => {
          if (!result) {
              return;
          }

          const file = new File([result], event.rowData.Name);
          if (navigator.msSaveBlob) {
              return navigator.msSaveBlob(file, file.name);
          }

          this.downloadAsHref(URL.createObjectURL(file), file.name);
      });
  }

  downloadAsHref(href: string, filename: string) {
      const tempDownloadLink = document.createElement("a");
      tempDownloadLink.href = href;
      tempDownloadLink.download = filename;

      document.body.appendChild(tempDownloadLink);
      tempDownloadLink.click();
      document.body.removeChild(tempDownloadLink);
  }

  deleteFile(event) {
      const dialogRef = this.dialog.open(ConfirmDialog, {
          data: {
              header: "Delete Canister File",
              text: `You are about to delete the file ${event.rowData.Name}. This action cannot be undone. Are you sure you wish to proceed?`,
          }
      });
  
      return dialogRef.afterClosed().subscribe((confirmed) => {
          if (confirmed) {
              this.canisterFileService.canistersCanisterIDFilesBlobNameDelete(this.canister.CanisterID, event.rowData.BlobName).subscribe(() => {
                this.alertService.pushAlert(new Alert(`Canister File was successfully deleted.`, AlertContext.Success, true));
                this.refreshData();
              }, error => {
                this.alertService.pushAlert(new Alert(`There was an error deleting the canister file. Please try again.`, AlertContext.Danger, true));
              });
          }
      });
  }

  formatFileSize(bytes, decimals = 2) {
      if (!+bytes) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  ngOnDestroy(): void {
      this.currentUserSubscription?.unsubscribe();
      this.canisterFilesSubscription?.unsubscribe();
  }

  canExit() {
      return !this.isLoading && !this.hasUnuploadedFiles;
  }
}
