<form class="form grid-12" #canisterForm="ngForm">
    <div class="g-col-6">
        <esa-material-ff
            name="VesselTypeID"
            [editMode]="editMode"
            [required]="true"
            label="Vessel Type"
            type="dropdown"
            [options]="vesselTypes$ | async"
            optionLabel="VesselTypeDisplayName"
            optionValue="VesselTypeID"
            [(ngModel)]="canisterUpsertDto.VesselTypeID"
        >
        </esa-material-ff>
    </div>

    <div class="g-col-6">
        <esa-material-ff
            name="PropertyOfGieStatus"
            [editMode]="editMode"
            type="dropdown"
            label="Owned by or Stored by Property of GIE Owner"
            [required]="true"
            [options]="propertyOfGieStatuses$ | async"
            optionLabel="PropertyOfGieStatusDisplayName"
            optionValue="PropertyOfGieStatusID"
            [(ngModel)]="canisterUpsertDto.PropertyOfGieStatusID"
        >
        </esa-material-ff>
    </div>

    <div class="g-col-6">
        <esa-material-ff
            name="GasContainerOrGasCartID"
            [editMode]="editMode"
            [required]="true"
            type="text"
            [(ngModel)]="canisterUpsertDto.GasContainerOrGasCartID"
            [customLabel]="GasContainerOrGasCartIDCustomLabel"
        >
        </esa-material-ff>
        <ng-template #GasContainerOrGasCartIDCustomLabel>
            <pog-custom-form-label
                label="Gas Container or Gas Cart ID"
                fieldDefinitionType="GasContainerOrGasCartID"
                [required]="true"
            ></pog-custom-form-label>
        </ng-template>
    </div>

    <div class="g-col-6">
        <esa-material-ff
            name="GasContainerNickname"
            [editMode]="editMode"
            label="Gas Container Nickname"
            type="text"
            [(ngModel)]="canisterUpsertDto.GasContainerNickname"
        >
        </esa-material-ff>
    </div>

    <div class="g-col-6">
        <esa-material-ff
            name="CoveredInsulatingGas"
            [editMode]="editMode"
            [required]="true"
            label="Covered Insulating Gas"
            type="dropdown"
            [options]="coveredInsulatingGases$ | async"
            optionLabel="CoveredInsulatingGasDisplayName"
            optionValue="CoveredInsulatingGasID"
            [(ngModel)]="canisterUpsertDto.CoveredInsulatingGasID"
        >
        </esa-material-ff>
    </div>

    <div class="g-col-6">
        <esa-material-ff
            name="TareWeight"
            [editMode]="editMode"
            label="Tare Weight (lbs)"
            type="text"
            [(ngModel)]="canisterUpsertDto.TareWeight"
        >
        </esa-material-ff>
    </div>

    <div class="g-col-6">
        <esa-material-ff
            name="CanisterStatus"
            [editMode]="editMode"
            type="dropdown"
            label="Status"
            [required]="true"
            [options]="canisterStatuses$ | async"
            optionLabel="CanisterStatusDisplayName"
            optionValue="CanisterStatusID"
            [(ngModel)]="canisterUpsertDto.CanisterStatusID"
            (ngModelChange)="handleCanisterStatusChange($event)"
            [customLabel]="CanisterStatusCustomLabel"
        >
        </esa-material-ff>
        <ng-template #CanisterStatusCustomLabel>
            <pog-custom-form-label
                label="Canister Status"
                fieldDefinitionType="CanisterStatus"
                [required]="true"
            ></pog-custom-form-label>
        </ng-template>
    </div>

    <div *ngIf="isRetirementDateRequired()" class="g-col-6">
        <esa-material-ff
            name="RetirementDate"
            [editMode]="editMode"
            label="Retirement Date"
            type="date"
            [required]="isRetirementDateRequired()"
            [(ngModel)]="canisterUpsertDto.RetirementDate"
        >
        </esa-material-ff>
    </div>

    <div class="required-note g-col-12">
        <i class="fas fa-bolt"></i> Required field
    </div>

    <div class="g-col-12 actions-bar" *ngIf="editMode" style="margin-top: 1rem">
        <esa-material-button
            style="margin-right: 1rem"
            type="primary"
            label="Save"
            icon="save"
            [tooltip]="
                !canisterForm.form.valid
                    ? 'You are missing required fields.'
                    : ''
            "
            (click)="saveForm(form)"
            [disabled]="!canisterForm.form.valid"
        >
        </esa-material-button>

        <esa-material-button
            type="secondary"
            label="Cancel"
            icon="cancel"
            (click)="cancelEditMode()"
        >
        </esa-material-button>
    </div>
</form>
