import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { CanisterService } from './api/canister.service';
import { CanisterFileService } from './api/canister-file.service';
import { CanisterStatusService } from './api/canister-status.service';
import { CoveredInsulatingGasService } from './api/covered-insulating-gas.service';
import { CustomPageService } from './api/custom-page.service';
import { CustomRichTextService } from './api/custom-rich-text.service';
import { DepartmentService } from './api/department.service';
import { EquipmentService } from './api/equipment.service';
import { EquipmentFileService } from './api/equipment-file.service';
import { EquipmentStatusService } from './api/equipment-status.service';
import { EquipmentTypeService } from './api/equipment-type.service';
import { FieldDefinitionService } from './api/field-definition.service';
import { ImpersonationService } from './api/impersonation.service';
import { ManufacturerService } from './api/manufacturer.service';
import { MenuItemService } from './api/menu-item.service';
import { ProjectService } from './api/project.service';
import { ProjectFileService } from './api/project-file.service';
import { PropertyOfGieStatusService } from './api/property-of-gie-status.service';
import { ReasonForMeasurementService } from './api/reason-for-measurement.service';
import { RoleService } from './api/role.service';
import { SealTypeService } from './api/seal-type.service';
import { StaticFileService } from './api/static-file.service';
import { SystemInfoService } from './api/system-info.service';
import { UserService } from './api/user.service';
import { UserClaimsService } from './api/user-claims.service';
import { VesselTypeService } from './api/vessel-type.service';
import { YearService } from './api/year.service';
import { YearEndWeightService } from './api/year-end-weight.service';
import { YearEndWeightFileService } from './api/year-end-weight-file.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    CanisterService,
    CanisterFileService,
    CanisterStatusService,
    CoveredInsulatingGasService,
    CustomPageService,
    CustomRichTextService,
    DepartmentService,
    EquipmentService,
    EquipmentFileService,
    EquipmentStatusService,
    EquipmentTypeService,
    FieldDefinitionService,
    ImpersonationService,
    ManufacturerService,
    MenuItemService,
    ProjectService,
    ProjectFileService,
    PropertyOfGieStatusService,
    ReasonForMeasurementService,
    RoleService,
    SealTypeService,
    StaticFileService,
    SystemInfoService,
    UserService,
    UserClaimsService,
    VesselTypeService,
    YearService,
    YearEndWeightService,
    YearEndWeightFileService,
     ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
